import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { detectMob, loadQueryString, numberWithCommas, toMillions, makeGET, makePOST, returnNumber } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import $ from 'jquery'
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { createFilter } from "react-select";
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'
import seccion from '../../imgs/seccion_amarilla.png'

$.DataTable = require('datatables.net')
require("datatables.net-responsive");

export const SeccionScreen = () => {
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    var x = new Date()

    var d = new Date(x.getFullYear(), x.getMonth() - 6, 1)
    x.setDate(0)
    var d1 = x
    let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
    strDate.setAttribute("value", moment(d).format("YYYY-MM-DD"))
    strDate.setAttribute("max", moment(d1).format("YYYY-MM-DD"))
    endDate.setAttribute("value", moment(d1).format("YYYY-MM-DD"))
    endDate.setAttribute("min", moment(d).format("YYYY-MM-DD"))

    getDatos();
  }, []);

  const [datos, setDatos] = useState([]);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState({ label: "TODOS", value: "TODOS" });
  const [dataSesiones, setDataSesiones] = useState(undefined);
  const [dataFuentes, setDataFuentes] = useState({
    labels: ["Usuarios viejos", "Usuarios nuevos"],
    datasets: [{
      backgroundColor: ["#58ac3f", "#0097a7"],
      data: [2, 1]
    }]
  });

  const [searches, setSearches] = useState([])
  let options = {
    legend: { display: true },
    elements: {
      line: {
        tension: 0
      }
    },
    animation: false,
    scales: {
      yAxes: {
        display: true,
        ticks: {
          beginAtZero: true
        }
      }
    }
  }

  let optionsFuentes = {
    animation: false,
  }

  const [searchTable, setSearchTable] = useState(undefined)

  const replace = (str, rep) => {
    return str.replace(/-/g, " ")
  }

  useEffect(() => {
    if (search != "") {
      getDatos()
    }
  }, [search])

  const getDatos = async () => {

    document.getElementById("loading").classList.remove("d-none")
    let page = "", hostname = "", searchTmp = ((search != "" && search != "TODOS") ? search : "")
    const isMobile = detectMob()
    document.getElementById("loading").classList.remove("d-none")
    const params = loadQueryString();

    if (params.search) {
      searchTmp = params.search
      document.getElementById("hosts").style.display = "none"
    }

    const sampleUrl = "files_protected/sampleadnplus.json"
    const url = "report/bqPro?" + "dateStr="
      + document.getElementById("strDate").value + "&dateEnd=" + document.getElementById("endDate").value
      + (searchTmp != "" ? "&search=" + searchTmp : "")

    let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
    strDate.setAttribute("max", endDate.value)
    endDate.setAttribute("min", strDate.value)

    let secs, formatted
    makeGET(params.test ? sampleUrl : url).then(({ data }) => {
      document.getElementById("loading").classList.add("d-none")
      secs = parseInt(data.tiempoTotal + "")
      formatted = moment.utc(secs * 1000).format('HH:mm:ss');
      /*const table = $(`#table1`).DataTable(
        {
          searching: false, info: false, "dom": 'rtip',
          order: [[1, 'desc']],
          "responsive": true
        }
      )*/

      $("#usuarios").text(returnNumber(data.usuariosTotal))
      $("#sesiones").text(returnNumber(data.sesionesTotal))
      $("#usuarios_new").text(returnNumber(data.newUsers))
      $("#sesiones_usuarios").text(returnNumber(parseFloat(data.sesionesTotal / data.usuariosTotal).toFixed(2)))

      $("#paginas_sesion").text(returnNumber(parseFloat(data.pageviewsTotal / data.sesionesTotal).toFixed(2)))
      $("#rebote").text("1.14%")
      $("#vistas").text(returnNumber(data.pageviewsTotal || 0))
      $("#duracion").text(formatted)

      data.months.sort((a, b) => (new Date(a.month) > new Date(b.month)) ? 1 : ((new Date(b.month) > new Date(a.month)) ? -1 : 0))

      const monthsParsed = data.months.map(v => v.monthParsed)
      const monthsSesiones = data.months.map(v => v.sesiones)
      const monthsUsuarios = data.months.map(v => v.usuarios)

      /*if (isMobile) {
        document.getElementById("sesionesBar1").style.height = (document.getElementById("sesionesBar1").offsetWidth - 100) + "px"
      }
      if (sesionesChart1) {
        sesionesChart1.destroy()
      }*/
      setDataSesiones({
        labels: monthsParsed,
        datasets: [{
          label: "Usuarios",
          type: "line",
          borderColor: "#58ac3f",
          backgroundColor: "#58ac3f",
          data: monthsUsuarios,
          fill: false
        },
        {
          label: "Sesiones",
          type: "line",
          borderColor: "#ac1e75",
          backgroundColor: "#ac1e75",
          data: monthsSesiones,
        }
        ]
      })


      setDataFuentes({
        labels: ["Usuarios viejos", "Usuarios nuevos"],
        datasets: [{
          backgroundColor: ["#58ac3f", "#0097a7"],
          data: [data.usuariosTotal - data.newUsers, data.newUsers]
        }]
      })
      setSearches([{ label: "TODOS", value: "TODOS" },
      ...data.searches.map(v => {
        return {
          label: replace(v.search, " "), value: v.search
        }
      })])
      setDatos({ ...data })

      if (searchTable) {
        searchTable.clear()
        searchTable.destroy()
      }

      setSearchTable($(`#search`).DataTable(
        {
          searching: false, info: false, "dom": 'rtip',
          order: [[1, 'desc']],
          "responsive": true,
          data: data.complete_searches,
          columns: [
            { "data": "complete_search" },
            { "data": "sesiones" },
          ]
        }
      ))
      setDateChanged(false)
      /*strDate.setAttribute("min", data.min_date)
      endDate.setAttribute("max", data.max_date)*/
      //if (new Date(strDate.value) < new Date(data.min_date)) strDate.value = data.min_date
      //if (new Date(endDate.value) > new Date(data.max_date)) endDate.value = data.max_date
    }).catch((e) => {
      document.getElementById("loading").classList.add("d-none")
    })
  };

  const [showOptions, setShowOptions] = useState(false);
  const handleInputChange = useCallback((typedOption) => {
    if (typedOption.length > 2) {
      setShowOptions(true);
    }
    else {
      setShowOptions(false);
    }
  }, []);

  const [dateChanged, setDateChanged] = useState(false)
  return (
    <div className="">
      <div className="loading d-none" id="loading">
        <Spinner animation="border" role="status"></Spinner>
      </div>
      <div id="firstRow" className="row p-3">
        <div className="col-12 col-md-4 pb-3 pt-3 pl-3 text-center">
          <img width="60%" src={seccion} />
        </div>
        <div className="col-12 col-md-4 pt-3">
          <Select
            options={(showOptions || searches.length < 1000) ? searches : []}
            autoFocus={false}
            className=""
            id="hosts"
            value={searchData}
            style={{ width: "100%", paddingTop: "1rem!important" }}
            isSearchable={true}
            noOptionsMessage={({ inputValue }) => "Escribe 2 o más letras para filtrar"}
            onInputChange={handleInputChange}
            filterOption={createFilter({ ignoreAccents: false })}
            onChange={(e) => {
              setSearch(e.value)
              setSearchData(e)
            }}
          />
        </div>
        <div className="col-12 col-md-4">
          <div className="row pt-0 pt-md-3 p-3">
            <div className="input-group">
              <input type="date" onChange={() => {
                let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
                strDate.setAttribute("max", endDate.value)
                endDate.setAttribute("min", strDate.value)
                setDateChanged(true)
              }} id="strDate" className="form-control" />
              <input type="date" onChange={() => {
                let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
                strDate.setAttribute("max", endDate.value)
                endDate.setAttribute("min", strDate.value)
                setDateChanged(true)
              }} id="endDate" className="form-control" />
              <button disabled={!dateChanged} onClick={getDatos} className="btn btn-primary"><i className="fa fa-search"></i></button>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 text-center mb-2">
          <p className="card-title font-weight-bold">Usuarios</p>
          <h5 className="card-text" id="usuarios">-</h5>
        </div>
        <div className="col-md-3 col-6 text-center mb-2">
          <p className="card-title font-weight-bold">Usuarios nuevos</p>
          <h5 className="card-text" id="usuarios_new">-</h5>
        </div>
        <div className="col-md-3 col-6 text-center mb-2">
          <p className="card-title font-weight-bold">Sesiones por usuario</p>
          <h5 className="card-text" id="sesiones_usuarios">-</h5>
        </div>
        <div className="col-md-3 col-6 text-center mb-2">
          <p className="card-title font-weight-bold">Sesiones</p>
          <h5 className="card-text" id="sesiones">-</h5>
        </div>
        <div className="col-md-4 col-6 text-center mb-2">
          <p className="card-title font-weight-bold">Vistas de pag.</p>
          <h5 className="card-text" id="vistas">-</h5>
        </div>
        <div className="col-md-4 col-6 text-center mb-2">
          <p className="card-title font-weight-bold">Páginas/Sesión</p>
          <h5 className="card-text" id="paginas_sesion">-</h5>
        </div>
        <div className="col-md-4 col-12 text-center mb-2">
          <p className="card-title font-weight-bold">Duración media</p>
          <h5 className="card-text" id="duracion">-</h5>
        </div>
        <div className="col-md-3 col-6 d-none">
          <p className="card-title font-weight-bold">% de rebote</p>
          <h5 className="card-text" id="rebote">-</h5>
        </div>
        <div className="col-md-8 col-12 mt-3 p-3">
          {dataSesiones &&
            <Bar options={options} data={dataSesiones} />
          }
        </div>
        <div className="col-md-4 col-12">
          {dataSesiones &&
            <Pie options={optionsFuentes} data={dataFuentes} />
          }
        </div>
        <div className="col-12 mt-3 d-none" style={{ height: "50px", backgroundColor: "#58ac3f" }}>
          <div className="row mt-2">
            <div className="col-12 text-center" style={{ height: "50px" }}>
              <h5 className="text-white">Acciones en el sitio web</h5>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3" style={{ height: "50px", backgroundColor: "#ac1e75" }}>
          <div className="row mt-2">
            <div className="col-12 text-center" style={{ height: "50px" }}>
              <h5 className="text-white">Sesiones por URL destino</h5>
            </div>
          </div>
        </div>
        <div className="col-12">
          <table id="search" className="display" style={{ height: "100%" }}>
            <thead style={{ backgroundColor: "#ac1e75", color: "white" }}>
              <tr id="searchHeader">
                <th>Search</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody id="searchBody">
              {/* {datos?.complete_searches?.map((v, i) => (
                <tr key={i}>
                  <td>{v.complete_search}</td>
                  <td>{v.sesiones}</td>
                </tr>
              ))} */}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
};
