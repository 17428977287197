import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { detectMob, loadQueryString, numberWithCommas, toMillions, makeGET, makePOST, addDays } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import $ from 'jquery'
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { createFilter } from "react-select";
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'
import adnplus from '../../imgs/logo-ADN-plus.png'

$.DataTable = require('datatables.net')
require("datatables.net-responsive");

export const AdnProScreen = () => {
    const user = useSelector((state) => state.auth);
    useEffect(() => {
        var x = new Date()

        var d = new Date(x.getFullYear(), x.getMonth() - 6, 1)
        x.setDate(0)
        var d1 = x
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("value", moment(d).format("YYYY-MM-DD"))
        strDate.setAttribute("max", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("value", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("min", moment(d).format("YYYY-MM-DD"))

        getDatos();
    }, []);

    const [datos, setDatos] = useState([]);
    const [page, setPage] = useState("");
    const [dataSesiones, setDataSesiones] = useState(undefined);

    const [pages, setPages] = useState([])
    const [pageTable, setPageTable] = useState(undefined)

    useEffect(() => {
        if (page != "") {
            getDatos()
        }
    }, [page])

    const getDatos = async () => {
        let hostname = "", pageTmp = (page != "" ? page : "")
        const isMobile = detectMob()
        document.getElementById("loading").classList.remove("d-none")
        const params = loadQueryString();

        if (params.page) {
            pageTmp = params.page
            document.getElementById("hosts").style.display = "none"
        }

        const sampleUrl = "files_protected/sampleadnpro.json"
        const url = "fb?" + "dateStr="
            + document.getElementById("strDate").value + "&dateEnd=" + document.getElementById("endDate").value
            + (pageTmp != "" ? "&page=" + pageTmp : "")

        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)

        makeGET(sampleUrl).then(({ data }) => {
            document.getElementById("loading").classList.add("d-none")
            setDatos({ ...data })

            if (pageTable) {
                pageTable.clear()
                pageTable.destroy()
            }

            $("#avg_rating").html('<i data-star="' + numberWithCommas(data.totals.avg_rating) + '"></i>')
            $("#comments").text(numberWithCommas(data.totals.comments))

            data.rows = data.rows.map(v => {
                return {
                    ...v,
                    total_reacts: numberWithCommas(v.total_reactions || "0"),
                    total_clics: numberWithCommas(v.clics || "0")
                }
            })

            let current_date = new Date()

            for (let i = 0; i < 100; i++) {
                data.rows = [{
                    pagina: "Pagina ejemplo " + i,
                    comment: "Comentario ejemplo " + i,
                    date_month: current_date.getFullYear() + "-" + (current_date.getMonth() + 1),
                    usuario: "Usuario ejemplo " + i,
                }, ...data.rows]

                current_date = addDays(current_date, 1)
            }

            setPageTable($(`#eventos`).DataTable(
                {
                    searching: false, info: false, "dom": 'rtip',
                    order: [[3, 'desc']],
                    "responsive": true,
                    data: data.rows,
                    columns: [
                        { "data": "pagina" },
                        { "data": "comment" },
                        { "data": "date_month" },
                        { "data": "usuario" }
                    ]
                }
            ))

            if (pageTmp == "") {
                setPages(data.pages.map(v => {
                    return {
                        label: v, value: v
                    }
                }))
            }
            setDateChanged(false)
        }).catch((e) => {
            document.getElementById("loading").classList.add("d-none")
        })
    };

    const [showOptions, setShowOptions] = useState(false);
    const handleInputChange = useCallback((typedOption) => {
        if (typedOption.length > 2) {
            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }
    }, []);
    const [dateChanged, setDateChanged] = useState(false)
    const submitDate = () => {
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)
        setDateChanged(true)
    }
    return (
        <div className="">
            <div className="loading d-none" id="loading">
                <Spinner animation="border" role="status"></Spinner>
            </div>
            <div id="firstRow" className="row pr-3 pl-3">
                <div className="col-12 col-md-4 pb-3 pt-3 pl-3 text-center">
                    <img width="60%" className="" src={adnplus} />
                </div>
                <div className="col-12 col-md-4">
                    <Select
                        options={pages}
                        autoFocus={false}
                        className="pt-3"
                        id="hosts"
                        style={{ width: "100%", paddingTop: "1rem!important" }}
                        isSearchable={true}
                        onInputChange={handleInputChange}
                        onChange={(e) => {
                            setPage(e.value)
                        }}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <div className="row pt-0 pt-md-3 p-3">
                        <div className="input-group">
                            <input type="date" onChange={submitDate} id="strDate" className="form-control" />
                            <input type="date" onChange={submitDate} id="endDate" className="form-control" />
                            <button disabled={!dateChanged} onClick={getDatos} className="btn btn-primary">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card text-white bg-primary mb-3 text-center">
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold">Calificación <i className="fa fa-star"></i></h5>
                            <h4 className="card-text" id="avg_rating"><i data-star="2.75"></i></h4>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card text-white bg-success mb-3 text-center">
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold">Comentarios <i className="fa fa-comment"></i></h5>
                            <h4 className="card-text" id="comments">506</h4>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <table id="eventos" className="display" style={{ width: "100%", maxWidth: "100%" }}>
                        <thead style={{ backgroundColor: "#58ac3f", color: "white" }}>
                            <tr>
                                <th>Página</th>
                                <th>Comentario</th>
                                <th>Mes</th>
                                <th>Usuario</th>
                            </tr>
                        </thead>
                        <tbody id="eventos"></tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
