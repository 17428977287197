import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { detectMob, loadQueryString, numberWithCommas, toMillions, makeGET, makePOST, returnNumber, addDays } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import $ from 'jquery'
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { createFilter } from "react-select";
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'
import adnlogo from '../../imgs/adn_logo.png'

$.DataTable = require('datatables.net')
require("datatables.net-responsive");

export const SitioAdnScreen = () => {
    const user = useSelector((state) => state.auth);
    useEffect(() => {
        var x = new Date()

        var d = new Date(x.getFullYear(), x.getMonth() - 6, 1)
        x.setDate(0)
        var d1 = x
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("value", moment(d).format("YYYY-MM-DD"))
        strDate.setAttribute("max", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("value", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("min", moment(d).format("YYYY-MM-DD"))

        getDatos();
    }, []);

    const hideEls = (key) => {
        var els = document.getElementsByClassName("hide" + key)
        for (var i = 0; i < els.length; i++) {
            els[i].style.display = "none"
            els[i].classList.remove("d-md-block")
            els[i].classList.remove("d-block")
        }
    }
    const [datos, setDatos] = useState([]);
    const [page, setPage] = useState([]);
    const [hostname, setHostname] = useState("");
    const [dataSesiones, setDataSesiones] = useState(undefined);
    const [dataSesiones1, setDataSesiones1] = useState(undefined);
    const [dataFuentes, setDataFuentes] = useState(undefined);
    const [dataMedios, setDataMedios] = useState(undefined);
    const [dataDevices, setDataDevices] = useState(undefined);
    const [hostnames, setHostnames] = useState([])
    const [hostData, setHostData] = useState({ label: "TODOS", value: "TODOS" })
    const [hostnamesFull, setHostnamesFull] = useState([])
    let options = {
        legend: { display: true },
        elements: {
            line: {
                tension: 0
            }
        },
        animation: false,
        scales: {
            yAxes: {
                display: true,
                ticks: {
                    beginAtZero: true
                }
            }
        }
    }
    let optionsFuentes = {
        animation: false,
    }

    let sesionesChart, sesionesChart1, fuentesChart, mediosChart, dispositivosChart
    let scrollTable
    let conversionesTable, gruposTable, terminosTable, topTable

    const [searchTable, setSearchTable] = useState(undefined)
    const [eventosTable, setEventosTable] = useState(undefined)
    const [dimensionesTable, setDimensionesTable] = useState(undefined)

    const replace = (str, rep) => {
        return str.replace(/-/g, " ")
    }

    useEffect(() => {
        if (hostname != "") {
            getDatos()
        }
    }, [hostname])

    const returnPieData = (arr, x, y) => {
        let xValues = arr.slice(0, 4).map(v => v[x])
        let yValues = arr.slice(0, 4).map(v => v[y])
        if (arr.length > 4) {
            xValues = [...xValues, "Otros"]
            yValues = [...yValues.slice(0, 4), yValues.slice((yValues.length - (yValues.length - 4)), yValues.length).reduce((partialSum, a) => partialSum + a, 0)]
        }

        let tempArr = xValues.map((v, i) => {
            return {
                label: v,
                data: yValues[i] || 0
            }
        })

        tempArr.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0))
        xValues = tempArr.map(v => v.label)
        yValues = tempArr.map(v => v.data)

        return { xValues, yValues }
    }

    const getDatos = async () => {
        let page = "", hostTmp = ((hostname != "" && hostname != "TODOS") ? hostname : "")
        const isMobile = detectMob()
        document.getElementById("loading").classList.remove("d-none")
        const params = loadQueryString();

        if (params.hostname) {
            hostTmp = params.hostname
            document.getElementById("hosts").style.display = "none"
        }

        const sampleUrl = "files_protected/sample3.json"
        const url = "report/bqFull?" + "dateStr="
            + document.getElementById("strDate").value + "&dateEnd=" + document.getElementById("endDate").value
            + (hostTmp != "" ? "&hostname=" + hostTmp : "")

        let secs, formatted

        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)

        makeGET(params.test ? sampleUrl : url).then(({ data }) => {
            document.getElementById("loading").classList.add("d-none")
            secs = parseInt(data.tiempoTotal + "")
            formatted = moment.utc(secs * 1000).format('HH:mm:ss');
            /*const table = $(`#table1`).DataTable(
              {
                searching: false, info: false, "dom": 'rtip',
                order: [[1, 'desc']],
                "responsive": true
              }
            )*/

            $("#usuarios").text(returnNumber(data.usuariosTotal))
            $("#sesiones").text(returnNumber(data.sesionesTotal))
            $("#sesiones_usuarios").text(returnNumber(parseFloat(data.sesionesTotal / data.usuariosTotal).toFixed(2)))
            $("#vistas").text(returnNumber(data.pageviewsTotal || 0))
            $("#duracion").text(formatted)

            data.months.sort((a, b) => (new Date(a.month) > new Date(b.month)) ? 1 : ((new Date(b.month) > new Date(a.month)) ? -1 : 0))

            const monthsParsed = data.months.map(v => v.monthParsed)
            const monthsPageViews = data.months.map(v => v.pageviews || 0)
            const monthsSesiones = data.months.map(v => v.sesiones)
            const monthsUsuarios = data.months.map(v => v.usuarios)
            const monthsNewUsers = data.months.map(v => v.newUsers)

            /*if (isMobile) {
              document.getElementById("sesionesBar1").style.height = (document.getElementById("sesionesBar1").offsetWidth - 100) + "px"
            }
            if (sesionesChart1) {
              sesionesChart1.destroy()
            }*/
            setDataSesiones({
                labels: monthsParsed,
                datasets: [{
                    label: "Vistas de una página",
                    type: "line",
                    borderColor: "#385574",
                    backgroundColor: "#385574",
                    data: monthsPageViews,
                    fill: false
                },
                {
                    label: "Sesiones",
                    type: "line",
                    borderColor: "#ac1e75",
                    backgroundColor: "#ac1e75",
                    data: monthsSesiones,
                    fill: false
                },
                {
                    label: "Usuarios",
                    type: "bar",
                    backgroundColor: "#58ac3f",
                    data: monthsUsuarios,
                }
                ]
            })
            setDataSesiones1({
                labels: monthsParsed,
                datasets: [{
                    label: "Usuarios nuevos",
                    type: "line",
                    borderColor: "#58ac3f",
                    backgroundColor: "#58ac3f",
                    data: monthsNewUsers,
                    fill: false
                },
                {
                    label: "Usuarios",
                    type: "bar",
                    borderColor: "#ac1e75",
                    backgroundColor: "#ac1e75",
                    data: monthsUsuarios,
                }
                ]
            })

            let pieData = returnPieData(data.sources, "source", "total")

            setDataFuentes({
                labels: pieData.xValues,
                datasets: [{
                    backgroundColor: ["#292b2c", "#56606b", "#ac1e75", "#58ac3f", "#0097a7"],
                    data: pieData.yValues
                }]
            })

            pieData = returnPieData(data.mediums, "medium", "total")

            setDataMedios({
                labels: pieData.xValues,
                datasets: [{
                    backgroundColor: ["#292b2c", "#0097a7", "#ac1e75", "#56606b", "#58ac3f"],
                    data: pieData.yValues
                }]
            })

            pieData = returnPieData(data.devices, "device", "total")

            setDataDevices({
                labels: pieData.xValues,
                datasets: [{
                    backgroundColor: ["#0097a7", "#58ac3f", "#ac1e75", "#56606b", "#292b2c"],
                    data: pieData.yValues
                }]
            })



            if (searchTable) {
                searchTable.clear()
                searchTable.destroy()
            }

            setSearchTable($(`#search`).DataTable(
                {
                    searching: false, info: false, "dom": 'rtip',
                    order: [[1, 'desc']],
                    "responsive": true,
                    data: data.searches,
                    columns: [
                        { "data": "search" },
                        { "data": "sesiones" },
                    ]
                }
            ))

            data.eventos = data.eventos.map(v => {
                return {
                    ...v,
                    usuariosc: numberWithCommas(v.usuarios),
                    sesionesc: numberWithCommas(v.sesiones)
                }
            })

            if (eventosTable) {
                eventosTable.clear()
                eventosTable.destroy()
            }

            setEventosTable($(`#eventos`).DataTable(
                {
                    searching: false, info: false, "dom": 'rtip',
                    order: [[4, 'desc']],
                    "responsive": true,
                    data: data.eventos,
                    columns: [
                        { "data": "eventAction" },
                        { "data": "eventCategory" },
                        { "data": "eventLabel" },
                        { "data": "usuariosc" },
                        { "data": "sesionesc" }
                    ]
                }
            ))

            data.dimensiones = data.dimensiones.map(v => {
                return {
                    ...v,
                    sesionesc: numberWithCommas(v.sesiones)
                }
            })

            if (dimensionesTable) {
                dimensionesTable.clear()
                dimensionesTable.destroy()
            }

            setDimensionesTable($(`#dimensiones`).DataTable(
                {
                    searching: false, info: false, "dom": 'rtip',
                    order: [[6, 'desc']],
                    "responsive": true,
                    data: data.dimensiones,
                    columns: [
                        { "data": "adId_busId" },
                        { "data": "n_comercial" },
                        { "data": "bc_product" },
                        { "data": "product_name" },
                        { "data": "c_postal" },
                        { "data": "town" },
                        { "data": "sesionesc" },
                        { "data": "category" },
                    ]
                }
            ))

            setHostnames([{ label: "TODOS", value: "TODOS" }, ...data.hostnames.map(v => {
                return {
                    label: v.hostname, value: v.hostname,
                    min: v.min_date, max: v.max_date
                }
            })])

            if (hostTmp == "") {


                setHostnamesFull(data.hostnames.map(v => {
                    return {
                        label: v.hostname, value: v.hostname,
                        min: v.min_date, max: v.max_date
                    }
                }))
            } else {

            }
            /* 
            let tmpHostnames = [...hostnames].filter(v => {
                                    return new Date(v.min) >= new Date(document.getElementById("strDate").value) &&
                                        new Date(document.getElementById("endDate").value) <= new Date(v.max)
                                })
                                console.log(tmpHostnames)
                                setHostnames([...tmpHostnames])
            */
            setDatos({ ...data })
            setDateChanged(false)
        }).catch((e) => {
            document.getElementById("loading").classList.add("d-none")
            console.log(JSON.stringify(e))
        })
    };


    const tableRef = useRef()
    // console.log(tableRef)
    const tableName = "search"

    const [showOptions, setShowOptions] = useState(false);
    const handleInputChange = useCallback((typedOption) => {
        if (typedOption.length > 2) {
            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }
    }, []);
    const dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
        if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
        if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
        if (b_start < a_start && a_end < b_end) return true; // a in b
        return false;
    }

    const [dateChanged, setDateChanged] = useState(false)
    const submitDate = () => {
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)
        setDateChanged(true)
    }
    return (
        <div className="">
            <div className="loading d-none" id="loading">
                <Spinner animation="border" role="status"></Spinner>
            </div>
            <div id="firstRow" className="row p-3">
                <div className="col-12 col-md-4 pb-3 pt-3 pl-3 text-center">
                    <img width="60%" src={adnlogo} />
                </div>
                <div className="col-12 col-md-4 pt-3">
                    <Select
                        options={(showOptions || hostnames.length < 1000) ? hostnames : []}
                        autoFocus={false}
                        className=""
                        id="hosts"
                        style={{ width: "100%", paddingTop: "1rem!important" }}
                        isSearchable={true}
                        noOptionsMessage={({ inputValue }) => "Escribe 2 o más letras para filtrar"}
                        onInputChange={handleInputChange}
                        value={hostData}
                        filterOption={createFilter({ ignoreAccents: false })}
                        onChange={(e) => {
                            setHostname(e.value)
                            setHostData(e)
                        }}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <div className="row pt-0 pt-md-3 p-3">
                        <div className="input-group">
                            <input type="date" onChange={() => {
                                submitDate()
                            }} id="strDate" className="form-control" />
                            <input type="date" onChange={() => {
                                submitDate()
                            }} id="endDate" className="form-control" />
                            <button disabled={!dateChanged} onClick={getDatos} className="btn btn-primary">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6 text-center mb-2">
                    <p className="card-title font-weight-bold">Usuarios</p>
                    <h5 className="card-text" id="usuarios">-</h5>
                </div>
                <div className="col-md-3 col-6 text-center mb-2">
                    <p className="card-title font-weight-bold">Sesiones</p>
                    <h5 className="card-text" id="sesiones">-</h5>
                </div>
                <div className="col-md-3 col-6 text-center mb-2">
                    <p className="card-title font-weight-bold">Vistas de pag.</p>
                    <h5 className="card-text" id="vistas">-</h5>
                </div>
                <div className="col-md-3 col-6 text-center mb-2">
                    <p className="card-title font-weight-bold">Duración media</p>
                    <h5 className="card-text" id="duracion">-</h5>
                </div>
                <div className="col-md-6 col-12 mt-3 p-3">
                    {dataSesiones &&
                        <Bar options={options} data={dataSesiones} />
                    }
                </div>
                <div className="col-md-6 col-12 mt-3 p-3">
                    {dataSesiones1 &&
                        <Bar options={options} data={dataSesiones1} />
                    }
                </div>
                <div className="col-12 d-none d-md-block" style={{ height: "50px", backgroundColor: "#0097a7" }}>
                    <div className="row mt-2">
                        <div className="col-4" style={{ height: "50px" }}>
                            <h5 className="text-white">Fuente</h5>
                        </div>
                        <div className="col-4" style={{ height: "50px" }}>
                            <h5 className="text-white">Medio</h5>
                        </div>
                        <div className="col-4" style={{ height: "50px" }}>
                            <h5 className="text-white">Dispositivo</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12">
                    <div style={{ height: "50px", backgroundColor: "#0097a7" }} className="d-lg-none">
                        <div style={{ height: "50px" }} className="text-center">
                            <h5 className="text-white">Fuente</h5>
                        </div>
                    </div>
                    {dataFuentes &&
                        <Pie options={optionsFuentes} data={dataFuentes} />
                    }
                </div>
                <div className="col-md-4 col-12">
                    <div style={{ height: "50px", backgroundColor: "#0097a7" }} className="d-lg-none">
                        <div style={{ height: "50px" }} className="text-center">
                            <h5 className="text-white">Medio</h5>
                        </div>
                    </div>
                    {dataMedios &&
                        <Pie options={optionsFuentes} data={dataMedios} />
                    }
                </div>
                <div className="col-md-4 col-12">
                    <div style={{ height: "50px", backgroundColor: "#0097a7" }} className="d-lg-none">
                        <div style={{ height: "50px" }} className="text-center">
                            <h5 className="text-white">Dispositivo</h5>
                        </div>
                    </div>
                    {dataDevices &&
                        <Pie options={optionsFuentes} data={dataDevices} />
                    }
                </div>
                <div className="col-12 mt-3" style={{ height: "50px", backgroundColor: "#58ac3f" }}>
                    <div className="row mt-2">
                        <div className="col-12 text-center" style={{ height: "50px" }}>
                            <h5 className="text-white">Acciones en el sitio web</h5>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <table id="eventos" className="display" style={{ width: "100%", maxWidth: "100%" }}>
                        <thead style={{ backgroundColor: "#58ac3f", color: "white" }}>
                            <tr>
                                <th>Acción</th>
                                <th>Categoría</th>
                                <th>Etiqueta</th>
                                <th>Usuarios</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody id="eventosBody"></tbody>
                    </table>
                </div>
                <div className="col-12 mt-3" style={{ height: "50px", backgroundColor: "#ac1e75" }}>
                    <div className="row mt-2">
                        <div className="col-12 text-center" style={{ height: "50px" }}>
                            <h5 className="text-white">Sesiones por URL destino</h5>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <table id="search" className="display" style={{ height: "100%" }}>
                        <thead style={{ backgroundColor: "#ac1e75", color: "white" }}>
                            <tr id="searchHeader">
                                <th>Search</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody id="searchBody"></tbody>
                    </table>
                </div>
                <div className="col-12 mt-3" style={{ height: "50px", backgroundColor: "#56606b" }}>
                    <div className="row mt-2">
                        <div className="col-12 text-center" style={{ height: "50px" }}>
                            <h5 className="text-white">Dimensiones personalizadas</h5>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <table id="dimensiones" className="display" style={{ height: "100%" }}>
                        <thead style={{ backgroundColor: "#56606b", color: "white" }}>
                            <tr>
                                <th>AdID-BusID</th>
                                <th>Nombre com.</th>
                                <th>BC</th>
                                <th>Producto</th>
                                <th>CP</th>
                                <th>Town</th>
                                <th>Total</th>
                                <th>Categoria</th>
                            </tr>
                        </thead>
                        <tbody id="dimensionesBody"></tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
