import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { detectMob, loadQueryString, numberWithCommas, toMillions, makeGET, makePOST } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import $ from 'jquery'
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { createFilter } from "react-select";
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'
import gads from '../../imgs/google_ads.png'

$.DataTable = require('datatables.net')
require("datatables.net-responsive");

export const AdsScreen = () => {
    const user = useSelector((state) => state.auth);
    useEffect(() => {
        var x = new Date()

        var d = new Date(x.getFullYear(), x.getMonth() - 6, 1)
        x.setDate(0)
        var d1 = x
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("value", moment(d).format("YYYY-MM-DD"))
        strDate.setAttribute("max", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("value", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("min", moment(d).format("YYYY-MM-DD"))

        getDatos();
    }, []);

    const hideEls = (key) => {
        var els = document.getElementsByClassName("hide" + key)
        for (var i = 0; i < els.length; i++) {
            els[i].style.display = "none"
            els[i].classList.remove("d-md-block")
            els[i].classList.remove("d-block")
        }
    }
    const [datos, setDatos] = useState([]);
    const [page, setPage] = useState([]);
    const [hostname, setHostname] = useState("");
    const [hostData, setHostData] = useState({ label: "TODOS", value: "TODOS" });
    const [dataSesiones, setDataSesiones] = useState(undefined);
    const [dataSesiones1, setDataSesiones1] = useState(undefined);
    const [dataFuentes, setDataFuentes] = useState(undefined);
    const [dataMedios, setDataMedios] = useState(undefined);
    const [dataDevices, setDataDevices] = useState(undefined);
    const [hostnames, setHostnames] = useState([])
    let options = {
        legend: { display: false },
        elements: {
            line: {
                tension: 0
            }
        },
        animation: false,
        scales: {
            yAxes: {
                display: true,
                ticks: {
                    beginAtZero: true
                }
            }
        }
    }
    let optionsFuentes = {
        animation: false,
    }

    let sesionesChart, sesionesChart1, fuentesChart, mediosChart, dispositivosChart
    let scrollTable

    const [conversionesTable, setConversionesTable] = useState(undefined)
    const [gruposTable, setGruposTable] = useState(undefined)
    const [terminosTable, setTerminosTable] = useState(undefined)
    const [topTable, setTopTable] = useState(undefined)

    const replace = (str, rep) => {
        return str.replace(/-/g, " ")
    }

    useEffect(() => {
        if (hostname != "") {
            getDatos()
        }
    }, [hostname])

    const returnPieData = (arr, x, y) => {
        let xValues = arr.slice(0, 4).map(v => v[x])
        let yValues = arr.slice(0, 4).map(v => v[y])
        if (arr.length > 4) {
            xValues = [...xValues, "Otros"]
            yValues = [...yValues.slice(0, 4), yValues.slice((yValues.length - (yValues.length - 4)), yValues.length).reduce((partialSum, a) => partialSum + a, 0)]
        }

        let tempArr = xValues.map((v, i) => {
            return {
                label: v,
                data: yValues[i] || 0
            }
        })

        tempArr.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0))
        xValues = tempArr.map(v => v.label)
        yValues = tempArr.map(v => v.data)

        return { xValues, yValues }
    }

    const getDatos = async () => {
        let page = "", hostTmp = ((hostname != "" && hostname != "TODOS") ? hostname : "")
        const isMobile = detectMob()
        document.getElementById("loading").classList.remove("d-none")
        const params = loadQueryString();

        if (params.campaign) {
            hostTmp = params.campaign
            document.getElementById("hosts").style.display = "none"
        }

        const sampleUrl = "files_protected/sampleads.json"
        const url = "report/bqAds?" + "dateStr="
            + document.getElementById("strDate").value + "&dateEnd=" + document.getElementById("endDate").value
            + (hostTmp != "" ? "&campaign=" + hostTmp : "")

        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)

        let secs, formatted
        makeGET(params.test ? sampleUrl : url).then(({ data }) => {
            document.getElementById("loading").classList.add("d-none")
            secs = parseInt(data.tiempoTotal + "")
            formatted = moment.utc(secs * 1000).format('HH:mm:ss');
            /*const table = $(`#table1`).DataTable(
              {
                searching: false, info: false, "dom": 'rtip',
                order: [[1, 'desc']],
                "responsive": true
              }
            )*/
            $("#clics").text(toMillions(data.resumen.clics || 0))
            $("#impresiones").text(toMillions(data.resumen.impressions || 0))
            $("#impresion_top").text(((data.resumen.top_impression || 0) * 100).toFixed(2) + "%")
            $("#costo_clic").text("$" + parseFloat((data.resumen.cost || 0) / data.resumen.clics).toFixed(2))
            $("#llamadas").text(toMillions(data.resumen.llamadas || 0))
            $("#impresiones_tel").text(toMillions(data.resumen.imp_llamadas || 0))
            $("#ctr").text(parseFloat(data.resumen.ctr || 0).toFixed(2) + "%")

            /*if (isMobile) {
              document.getElementById("sesionesBar1").style.height = (document.getElementById("sesionesBar1").offsetWidth - 100) + "px"
            }
            if (sesionesChart1) {
              sesionesChart1.destroy()
            }*/
            setDataSesiones({
                labels: data.clics_periodo.map(v => v.fecha.value),
                datasets: [{
                    label: "Clics",
                    type: "line",
                    borderColor: "#385574",
                    backgroundColor: "#385574",
                    data: data.clics_periodo.map(v => v.clics),
                    fill: false
                }
                ]
            })
            setDataSesiones1({
                labels: data.clics_hora.map(v => (v.hora > 12 ? v.hora - 12 :
                    (v.hora == 0 ? v.hora + 12 : v.hora)) + (v.hora < 12 ? "AM" : "PM")),
                datasets: [{
                    label: "Clics",
                    type: "line",
                    borderColor: "#58ac3f",
                    backgroundColor: "#58ac3f",
                    data: data.clics_hora.map(v => v.clics),
                    fill: false
                },
                {
                    label: "Llamadas",
                    type: "line",
                    borderColor: "#ac1e75",
                    backgroundColor: "#ac1e75",
                    data: data.clics_hora.map(v => v.llamadas),
                    fill: false
                }
                ]
            })

            let pieData = returnPieData(data.ubicaciones, "estado", "clics")

            setDataMedios({
                labels: pieData.xValues,
                datasets: [{
                    backgroundColor: ["#292b2c", "#56606b", "#ac1e75", "#58ac3f", "#0097a7"],
                    data: pieData.yValues
                }]
            })

            pieData = returnPieData(data.dispositivos, "dispositivo", "clics")

            setDataDevices({
                labels: pieData.xValues,
                datasets: [{
                    backgroundColor: ["#292b2c", "#56606b", "#ac1e75", "#58ac3f", "#0097a7"],
                    data: pieData.yValues
                }]
            })



            if (conversionesTable) {
                conversionesTable.clear()
                conversionesTable.destroy()
            }

            data.conversiones_sitio = data.conversiones_sitio.map(v => {
                return { ...v, conversionesc: numberWithCommas(v.conversiones) }
            })

            setConversionesTable($(`#conversionesTable`).DataTable(
                {
                    "sScrollY": 400,
                    searching: false, info: false, "dom": 'rtip',
                    order: [[1, 'desc']],
                    "responsive": true,
                    data: data.conversiones_sitio,
                    columns: [
                        { "data": "conversion" },
                        { "data": "conversionesc" },
                    ]
                }
            ))

            data.grupo_anuncios = data.grupo_anuncios.map(v => {
                return {
                    ...v,
                    impresionesc: numberWithCommas(v.impresiones),
                    clicsc: numberWithCommas(v.clics),
                    llamadasc: numberWithCommas(v.llamadas),
                }
            })

            if (gruposTable) {
                gruposTable.clear()
                gruposTable.destroy()
            }

            setGruposTable($(`#gruposTable`).DataTable(
                {
                    "sScrollY": 400,
                    searching: false, info: false, "dom": 'rtip',
                    order: [[1, 'desc']],
                    "responsive": true,
                    data: data.grupo_anuncios,
                    columns: [
                        { "data": "nombre" },
                        { "data": "impresionesc" },
                        { "data": "clicsc" },
                        { "data": "ctr" },
                        { "data": "llamadasc" }
                    ]
                }
            ))

            if (terminosTable) {
                terminosTable.clear()
                terminosTable.destroy()
            }

            data.terminos = data.terminos.map(v => {
                return {
                    ...v,
                    impresionesc: numberWithCommas(v.impresiones),
                    clicsc: numberWithCommas(v.clics),
                }
            })

            setTerminosTable($(`#terminosTable`).DataTable(
                {
                    "sScrollY": 400,
                    searching: false, info: false, "dom": 'rtip',
                    order: [[1, 'desc']],
                    "responsive": true,
                    data: data.terminos,
                    columns: [
                        { "data": "search_term" },
                        { "data": "clicsc" },
                        { "data": "impresionesc" }
                    ]
                }
            ))

            if (topTable) {
                topTable.clear()
                topTable.destroy()
            }

            data.palabras_clave = data.palabras_clave.map(v => {
                return {
                    ...v,
                    impresionesc: numberWithCommas(v.impresiones),
                    clicsc: numberWithCommas(v.clics),
                    llamadasc: numberWithCommas(v.llamadas),
                }
            })

            setTopTable($(`#topTable`).DataTable(
                {
                    "sScrollY": 400,
                    searching: false, info: false, "dom": 'rtip',
                    order: [[1, 'desc']],
                    "responsive": true,
                    data: data.palabras_clave,
                    columns: [
                        { "data": "keyword" },
                        { "data": "clicsc" },
                        { "data": "impresionesc" },
                        { "data": "llamadasc" },
                        { "data": "ctr" }
                    ]
                }
            ))

            setHostnames([{ label: "TODOS", value: "TODOS" }, ...data.campaigns.map(v => {
                return {
                    label: v.campaign_name, value: v.customer_id
                }
            })])

            /*if (hostTmp == "") {
                setHostnames(data.campaigns.map(v => {
                    return {
                        label: v.campaign_name, value: v.customer_id
                    }
                }))
            }*/
            setDatos({ ...data })
            setDateChanged(false)
        }).catch((e) => {
            document.getElementById("loading").classList.add("d-none")
            console.log(JSON.stringify(e))
        })
    };


    const tableRef = useRef()
    // console.log(tableRef)
    const tableName = "search"

    const [showOptions, setShowOptions] = useState(false);
    const handleInputChange = useCallback((typedOption) => {
        if (typedOption.length > 2) {
            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }
    }, []);

    const [dateChanged, setDateChanged] = useState(false)
    const submitDate = () => {
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)
        setDateChanged(true)
    }
    return (
        <div className="">
            <div className="loading d-none" id="loading">
                <Spinner animation="border" role="status"></Spinner>
            </div>
            <div id="firstRow" className="row p-3">
                <div className="col-12 col-md-4 pb-3 pt-3 pl-3 text-center">
                    <img width="60%" src={gads} />
                </div>
                <div className="col-12 col-md-4 pt-3">
                    <Select
                        options={(showOptions || hostnames < 1000) ? hostnames : []}
                        autoFocus={false}
                        className=""
                        id="hosts"
                        style={{ width: "100%", paddingTop: "1rem!important" }}
                        isSearchable={true}
                        noOptionsMessage={({ inputValue }) => "Escribe 2 o más letras para filtrar"}
                        onInputChange={handleInputChange}
                        filterOption={createFilter({ ignoreAccents: false })}
                        value={hostData}
                        onChange={(e) => {
                            setHostname(e.value)
                            setHostData(e)
                        }}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <div className="row pt-0 pt-md-3 p-3">
                        <div className="input-group">
                            <input type="date" onChange={submitDate} id="strDate" className="form-control" />
                            <input type="date" onChange={submitDate} id="endDate" className="form-control" />
                            <button disabled={!dateChanged} onClick={getDatos} className="btn btn-primary">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">Clics</p>
                    <h5 className="card-text" id="clics">-</h5>
                </div>
                <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">Impresiones</p>
                    <h5 className="card-text" id="impresiones">-</h5>
                </div>
                <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">Impresión (top) %</p>
                    <h5 className="card-text" id="impresion_top">-</h5>
                </div>
                <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">Costo x clic</p>
                    <h5 className="card-text" id="costo_clic">-</h5>
                </div>
                <div className="col-4 text-center">
                    <p className="card-title font-weight-bold">Llamadas</p>
                    <h5 className="card-text" id="llamadas">-</h5>
                </div>
                <div className="col-4 text-center">
                    <p className="card-title font-weight-bold">Impresiones de teléfono</p>
                    <h5 className="card-text" id="impresiones_tel">-</h5>
                </div>
                <div className="col-md-4 col-12 text-center">
                    <p className="card-title font-weight-bold">CTR</p>
                    <h5 className="card-text" id="ctr">-</h5>
                </div>
                <br />
                <div className="col-md-6 col-12 mt-3">
                    <h5 className="font-weight-bold text-center">Conversiones en sitio</h5>
                    <table id="conversionesTable" className="display" style={{ width: "100%", maxWidth: "100%" }}>
                        <thead className="rounded-top" style={{ backgroundColor: "#0c6cfc", color: "white" }}>
                            <tr>
                                <th>Nombre</th>
                                <th>Conversión</th>
                            </tr>
                        </thead>
                        <tbody id="conversionesBody"></tbody>
                    </table>
                </div>
                <div className="col-md-6 col-12 mt-3">
                    <h5 className="font-weight-bold text-center">Grupos de anuncios</h5>
                    <table id="gruposTable" className="display" style={{ width: "100%", maxWidth: "100%", maxHeight: "100px" }}>
                        <thead style={{ backgroundColor: "#0c6cfc", color: "white" }}>
                            <tr>
                                <th>Grupo de anuncios</th>
                                <th>Impresiones</th>
                                <th>Clics</th>
                                <th>CTR</th>
                                <th>Llamadas</th>
                            </tr>
                        </thead>
                        <tbody id="gruposBody"></tbody>
                    </table>
                </div>
                <div className="col-md-6 col-12 mt-3 p-3">
                    {dataSesiones &&
                        <Bar options={options} data={dataSesiones} />
                    }
                </div>
                <div className="col-md-6 col-12 mt-3 p-3">
                    {dataSesiones1 &&
                        <Bar options={options} data={dataSesiones1} />
                    }
                </div>
                <div className="col-md-6 col-12 mt-3">
                    <h5 className="font-weight-bold text-center">Términos de búsqueda</h5>
                    <table id="terminosTable" className="display" style={{ width: "100%", maxWidth: "100%", maxHeight: "100px" }}>
                        <thead style={{ backgroundColor: "#0c6cfc", color: "white" }}>
                            <tr>
                                <th>Término de búsqueda</th>
                                <th>Clics</th>
                                <th>Impresiones</th>
                            </tr>
                        </thead>
                        <tbody id="terminosBody"></tbody>
                    </table>
                </div>
                <div className="col-md-6 col-12 mt-3">
                    <h5 className="font-weight-bold text-center">Top de palabras clave</h5>
                    <table id="topTable" className="display" style={{ width: "100%", maxWidth: "100%", maxHeight: "100px" }}>
                        <thead style={{ backgroundColor: "#0c6cfc", color: "white" }}>
                            <tr>
                                <th>Palabra clave</th>
                                <th>Clics</th>
                                <th>Impresiones</th>
                                <th>Llamadas</th>
                                <th>CTR</th>
                            </tr>
                        </thead>
                        <tbody id="topBody"></tbody>
                    </table>
                </div>
                <div className="col-md-6 col-12 mt-3">
                    <h5 className="font-weight-bold text-center">Clics por dispositivo</h5>
                    {dataDevices &&
                        <Pie options={optionsFuentes} data={dataDevices} />
                    }
                </div>
                <div className="col-md-6 col-12 mt-3">
                    <h5 className="font-weight-bold text-center">Clics por ubicación geográfica</h5>
                    {dataMedios &&
                        <Pie options={optionsFuentes} data={dataMedios} />
                    }
                </div>
            </div>
        </div>
    );
};
