import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { detectMob, loadQueryString, numberWithCommas, toMillions, makeGET, makePOST } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import $, { data } from 'jquery'
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { createFilter } from "react-select";
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'
import iso from 'iso-3166-1'

import searchlogo from '../../imgs/search_console.png'

$.DataTable = require('datatables.net')
require("datatables.net-responsive");

export const SearchScreen = () => {
    const user = useSelector((state) => state.auth);
    useEffect(() => {
        var x = new Date()
        var d = new Date(x.getFullYear(), x.getMonth() - 6, 1)
        x.setDate(0)
        var d1 = x
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")

        strDate.setAttribute("value", moment(d).format("YYYY-MM-DD"))
        strDate.setAttribute("max", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("value", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("min", moment(d).format("YYYY-MM-DD"))

        getDatos();
    }, []);

    const [datos, setDatos] = useState([]);
    const [site, setSite] = useState("");
    const [siteData, setSiteData] = useState({ label: "TODOS", value: "TODOS" });
    const [dataSesiones, setDataSesiones] = useState(undefined);
    const [dataMeses, setDataMeses] = useState(undefined);

    const [sitios, setSitios] = useState([])
    let options = {
        legend: { display: true },
        elements: {
            line: {
                tension: 0
            }
        },
        animation: false,
        scales: {
            yAxes: {
                display: true,
                ticks: {
                    beginAtZero: true
                }
            }
        }
    }

    const [searchTable, setSearchTable] = useState(undefined)

    useEffect(() => {
        if (site != "") {
            getDatos()
        }
    }, [site])

    const getDatos = async () => {
        let hostname = "", siteTmp = ((site != "" && site != "TODOS") ? site : "")
        const isMobile = detectMob()
        document.getElementById("loading").classList.remove("d-none")
        const params = loadQueryString();

        if (params.site) {
            siteTmp = params.site
            document.getElementById("hosts").style.display = "none"
        }

        const sampleUrl = "files_protected/sampleSearch.json"
        const url = "report/bqSearch?" + "dateStr="
            + document.getElementById("strDate").value + "&dateEnd=" + document.getElementById("endDate").value
            + (siteTmp != "" ? "&siteUrl=" + siteTmp : "")

        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)

        makeGET(params.test ? sampleUrl : url).then(({ data }) => {
            document.getElementById("loading").classList.add("d-none")

            $("#clics").text(numberWithCommas(data.resumen.clicks))
            $("#impresiones").text(numberWithCommas(data.resumen.impresiones))
            $("#ctr").text((data.resumen.ctr).toFixed(1) + "%")

            const mapDevices = {
                DESKTOP: "Computadoras",
                MOBILE: "Móviles",
                TABLET: "Tabletas"
            }

            let xValues = data.dispositivos.slice(0, 4).map(v => mapDevices[v.device])
            let yValues = data.dispositivos.slice(0, 4).map(v => v.clicks)

            if (data.dispositivos.length > 4) {
                xValues = [...xValues, "Otros"]
                yValues = [...yValues.slice(0, 4), yValues.slice((yValues.length - (yValues.length - 4)), yValues.length).reduce((partialSum, a) => partialSum + a, 0)]
            }

            let tempArr = xValues.map((v, i) => {
                return {
                    label: v,
                    data: yValues[i] || 0
                }
            })

            tempArr.sort((a, b) => (a.data < b.data) ? 1 : ((b.data < a.data) ? -1 : 0))
            xValues = tempArr.map(v => v.label)
            yValues = tempArr.map(v => v.data)

            /*if (isMobile) {
              document.getElementById("sesionesBar1").style.height = (document.getElementById("sesionesBar1").offsetWidth - 100) + "px"
            }
            if (sesionesChart1) {
              sesionesChart1.destroy()
            }*/
            setDataMeses({
                labels: data.meses.map(v => v.date),
                datasets: [{
                    label: "Clics",
                    type: "line",
                    borderColor: "#0097a7",
                    backgroundColor: "#0097a7",
                    data: data.meses.map(v => v.clicks)
                }
                ]
            })

            setDataSesiones({
                labels: xValues,
                datasets: [{
                    label: "Clics",
                    backgroundColor: ["#0097a7", "#58ac3f", "#ac1e75", "#56606b", "#292b2c"],
                    data: yValues
                }]
            })

            setDatos({ ...data })

            if (searchTable) {
                searchTable.clear()
                searchTable.destroy()
            }

            setSearchTable($(`#eventosFB`).DataTable(
                {
                    searching: false, info: false, "dom": 'rtip',
                    order: [[4, 'desc']],
                    "responsive": true,
                    data: data.rows,
                    columns: [
                        { "data": "name" },
                        { "data": "n_posts" },
                        { "data": "date_month" },
                        { "data": "total_reacts" },
                        { "data": "total_clics" },
                    ]
                }
            ))

            setSitios([{ label: "TODOS", value: "TODOS" }, ...data.sitios.map(v => {
                return {
                    label: v.site, value: v.site
                }
            })])

            /*if (siteTmp == "") {
                setSitios(data.sitios.map(v => {
                    return {
                        label: v.site, value: v.site
                    }
                }))
            }*/
            setDateChanged(false)
        }).catch((e) => {
            console.log(e)
            document.getElementById("loading").classList.add("d-none")
        })
    };

    const [showOptions, setShowOptions] = useState(false);
    const handleInputChange = useCallback((typedOption) => {
        if (typedOption.length > 2) {
            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }
    }, []);

    const [dateChanged, setDateChanged] = useState(false)
    const submitDate = () => {
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)
        setDateChanged(true)
    }

    const mapColorsQuery = ["#92b4f2", "#ccddfc", "#e9f0fd"]
    return (
        <div className="">
            <div className="loading d-none" id="loading">
                <Spinner animation="border" role="status"></Spinner>
            </div>
            <div id="firstRow" className="row pr-3 pl-3">
                <div className="col-12 col-md-4 pb-3 pt-3 pl-3 text-center">
                    <img width="60%" className="" src={searchlogo} />
                </div>
                <div className="col-12 col-md-4">
                    <Select
                        options={sitios}
                        autoFocus={false}
                        className="pt-3"
                        id="hosts"
                        style={{ width: "100%", paddingTop: "1rem!important" }}
                        isSearchable={true}
                        value={siteData}
                        onInputChange={handleInputChange}
                        onChange={(e) => {
                            setSite(e.value)
                            setSiteData(e)
                        }}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <div className="row pt-0 pt-md-3 p-3">
                        <div className="input-group">
                            <input type="date" onChange={submitDate} id="strDate" className="form-control" />
                            <input type="date" onChange={submitDate} id="endDate" className="form-control" />
                            <button disabled={!dateChanged} onClick={getDatos} className="btn btn-primary">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-6 text-center">
                    <button type="button" className="btn btn-primary rounded-circle btn-xl text-center mb-1"><i className="fa fa-mouse-pointer"></i></button>
                    <h5 className="card-text" id="clics">-</h5>
                    <p className="card-title font-weight-bold" >Clics (web)</p>
                </div>
                <div className="col-md-4 col-6 text-center">
                    <button type="button" className="btn btn-success rounded-circle btn-xl text-center mb-1"><i className="fa fa-eye"></i></button>
                    <h5 className="card-text" id="impresiones">-</h5>
                    <p className="card-title font-weight-bold" >Impresiones</p>
                </div>
                <div className="col-md-4 col-12 text-center">
                    <button type="button" className="btn btn-danger rounded-circle btn-xl text-center mb-1"><i className="fa fa-line-chart"></i></button>
                    <h5 className="card-text" id="ctr">-</h5>
                    <p className="card-title font-weight-bold" >CTR</p>
                </div>

                <div className="col-md-6 col-12 text-center mt-3">
                    <h4>Dispositivos</h4>
                    <p className="card-title font-weight-bold" >Por cantidad de clics</p>
                    {dataSesiones && <Bar options={options} data={dataSesiones} />}
                </div>
                <div className="col-md-6 col-12 text-center mt-3">
                    <div className="card w-100" style={{ width: "18rem" }}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold">Páginas que más crecieron</h5>
                            <p className="card-text">Por cantidad de clics</p>
                        </div>
                        {datos.paginas &&
                            <div className="row pl-3 pr-3">
                                <div className="col-9 text-muted border-bottom">Página</div>
                                <div className="col-3 text-muted border-bottom"><p>Clics</p></div>

                                {datos.paginas.slice(0, 3).map((v, i) => (
                                    <>
                                        <div className="col-8 text-center border-bottom pb-3">{v.page}</div>
                                        <div className="col-4 border-bottom pb-3 rounded text-center pt-1" style={{ textAlign: "right" }}>
                                            <button type="button" className="btn btn-info rounded btn-xl text-center btn-lg btn-block text-white">
                                                {numberWithCommas(v.clicks)}
                                            </button>
                                        </div>
                                    </>
                                ))}
                            </div>
                        }

                    </div>
                </div>
                <div className="col-md-6 col-12 text-center mt-3">
                    <div className="card w-100 border-0" style={{ width: "18rem", paddingLeft: "10px", paddingRight: "10px" }}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold">Búsquedas que más crecieron</h5>
                            <p className="card-text">Por cantidad de clics</p>
                        </div>
                        {datos.busquedas &&
                            <div className="row">
                                {datos.busquedas.slice(0, 3).map((v, i) => (
                                    <div className="col-12 rounded mb-3 pt-2" style={{ backgroundColor: mapColorsQuery[i], height: "80px" }}>
                                        <p>
                                            <strong>{v.query}</strong><br />
                                            <span>{numberWithCommas(v.clicks)} clics</span>
                                        </p>
                                    </div>
                                ))}

                            </div>
                        }

                    </div>
                </div>
                <div className="col-md-6 col-12 text-center mt-3">
                    <div className="card w-100" style={{ width: "18rem" }}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold">Principales países</h5>
                            <p className="card-text">Por cantidad de clics</p>
                        </div>
                        <div className="row pl-3 pr-3">
                            {datos.paises &&
                                <>
                                    {datos.paises.slice(0, 3).map((v, i) => (
                                        <>
                                            <div className="col-2 text-muted border-bottom pb-1 pt-1">
                                                <img src={"https://flagcdn.com/w160/" + iso.whereCountry(v.country).alpha2.toLowerCase() + ".png"}
                                                    width="100%" />
                                            </div>
                                            <div className="col-4 border-bottom pb-1 pt-1" style={{ textAlign: "left" }}>
                                                <h6>{v.country}</h6>
                                            </div>
                                            <div className="col-6 border-bottom pb-1 pt-1" style={{ textAlign: "center" }}>
                                                <h6>{numberWithCommas(v.clicks)}</h6>
                                            </div>
                                        </>
                                    ))}

                                </>
                            }
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-12 text-center mt-3 mb-3">
                    <div className="card w-100" style={{ width: "18rem" }}>
                        <div className="card-body">
                            <h5 className="card-title font-weight-bold">Tipo de búsqueda de Google</h5>
                            <p className="card-text">Por cantidad de clics</p>
                        </div>
                        <div className="row pl-3 pr-3">

                            {datos.tipo &&
                                <>
                                    {datos.tipo.slice(0, 3).map((v, i) => (
                                        <>
                                            <div className="col-2 text-muted border-bottom pb-1">
                                                <img src={conf.apiURL + "files/" + v.searchType + ".PNG"} width="100%" />
                                            </div>
                                            <div className="col-4 border-bottom pb-1 pt-1" style={{ textAlign: "left" }}>
                                                <h6>{v.searchType}</h6>
                                            </div>
                                            <div className="col-6 border-bottom pb-1 pt-1" style={{ textAlign: "center" }}>
                                                <h6>{numberWithCommas(v.clicks)}</h6>
                                            </div>
                                        </>
                                    ))}

                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 mt-3 p-3">
                    {dataMeses && <Bar options={options} data={dataMeses} />}
                </div>
            </div>
        </div>
    );
};
