import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { startLogout } from '../../actions/auth';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { InicioScreen } from './InicioScreen';
import { SeccionScreen } from './SeccionScreen';
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import { SitioAdnScreen } from './SitioAdnScreen';
import { FacebookScreen } from './FacebookScreen';
import { AdsScreen } from './AdsScreen';
import { loadQueryString } from '../../helpers/makeRequest';
import { AdnProScreen } from './AdnProScreen';
import { SearchScreen } from './SearchScreen';
import seccion from '../../imgs/seccion_amarilla.png'
import adnlogo from '../../imgs/adn_logo.png'
import andplus from '../../imgs/logo-ADN-plus.png'
import facebook from '../../imgs/facebook_logo.png'
import gads from '../../imgs/google_ads.png'
import searchlogo from '../../imgs/search_console.png'
import minegocio from '../../imgs/logo_mi_negocio.png'
import ialogo from '../../imgs/ia.png'
import facebook_ads from '../../imgs/facebook-ads.svg'
import { CoachScreen } from './CoachScreen';
import { FacebookAdsScreen } from './FacebookAds';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import $ from 'jquery'
import { bootstrap } from '../../helpers/bootstrapTxt';
import Swal from 'sweetalert2';

export const PrincipalScreen = () => {
    const { actualPage } = useParams()
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(startLogout())
    }
    const history = useHistory()
    const user = useSelector(state => state.auth);
    const [page, setPage] = useState(actualPage == ':actualPage' ? (userData?.rol == "admin" ? 'franquicia' : 'inicio') : actualPage)

    const handleChangeOfRoute = (route) => {
        setPage(route)
        const href = window.location.href.includes("?") ? window.location.href.substring(window.location.href.indexOf("?")) : ""
        history.push('/' + route + href)
    }
    //const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userProps")))
    const [userData, setUserData] = useState({})
    const [reloading, setReloading] = useState(false)
    const [collapsed, setCollapsed] = useState(false)

    const returnNumeros = async (label) => {
        let els = $('.' + label)
        let labels = $("." + label + "-labels")
        let mapNumeros = ""
        for (var i = 0; i < els.length; i++) {
            mapNumeros = mapNumeros + `<div class="col-12" style="height: 25%">
                <div class="row pl-3 pb-2">
                    <div class="col-3 m-0 p-0">
                        <img width="100%" src="${(await html2canvas(els[i])).toDataURL('image/png')}">
                    </div>
                    <div class="col-9 text-left">
                        <p class="text-secondary m-0 p-0" style="font-size: 18px">${labels[i].getElementsByTagName("p")[0].textContent}</p>
                        <p class="text-secondary m-0 p-0" style="font-size: 10px">${labels[i].getElementsByTagName("p")[1].innerHTML}</p>
                    </div>
                </div>
            </div>`
        }
        return mapNumeros
    }

    const print = async () => {
        document.getElementById("loading").classList.remove("d-none")

        let firstDivs = []
        let els = $('*[class^="hide"]')
        for (var i = 0; i < els.length; i++) {
            if (!els[i].classList.contains("d-none")) {
                firstDivs = $(els[i]).find(".col-6")
            }
        }

        const imgs = [
            (await html2canvas($("#first-number")[0])).toDataURL('image/png'),
            (await html2canvas($("#second-number")[0])).toDataURL('image/png'),
            (await html2canvas($("#third-number")[0])).toDataURL('image/png'),
            (await html2canvas($("#fourth-number")[0])).toDataURL('image/png'),
            (await html2canvas($("div[data-tooltip-id='my-tooltip']")[0])).toDataURL('image/png'),
            (await html2canvas($("div[data-tooltip-id='my-tooltip']")[1])).toDataURL('image/png'),
            (await html2canvas($("div[data-tooltip-id='my-tooltip']")[2])).toDataURL('image/png'),
            (await html2canvas($("div[data-tooltip-id='my-tooltip']")[3])).toDataURL('image/png'),
            (await html2canvas($("#hosts")[0])).toDataURL('image/png'),
            (await html2canvas(firstDivs[0])).toDataURL('image/png'),
            (await html2canvas(firstDivs[1])).toDataURL('image/png'),
            (await html2canvas(firstDivs[2])).toDataURL('image/png'),
            (await html2canvas(firstDivs[3])).toDataURL('image/png'),
            (await html2canvas($("#barra1")[0])).toDataURL('image/png'),
            (await html2canvas($("#barra2")[0])).toDataURL('image/png'),
            (await html2canvas($("#polar1")[0])).toDataURL('image/png'),
            (await html2canvas($("#pais")[0])).toDataURL('image/png'),
            (await html2canvas($("#mundial")[0])).toDataURL('image/png'),
            (await returnNumeros("number1")),
            (await returnNumeros("number")),
        ]

        let metricasTxt = []
        for (var i = 0; i < firstDivs.length; i++) {
            metricasTxt = [...metricasTxt, firstDivs[i].getElementsByTagName("h5")[0].innerText.trim()]
        }

        const resumen = `<div class="col-3">
            <img style="max-height: 100px; max-width: 100%" src="${imgs[4]}">
        </div>
        <div class="col-3">
            <img style="max-height: 100px; max-width: 100%" src="${imgs[5]}">
        </div>
        <div class="col-3">
            <img style="max-height: 100px; max-width: 100%" src="${imgs[6]}">
        </div>
        <div class="col-3">
            <img style="max-height: 100px; max-width: 100%" src="${imgs[7]}">
        </div>`

        const resumen_metricas = `<div class="col-3 mt-3">
            <img style="max-height: 100px; max-width: 100%" src="${imgs[9]}">
        </div>
        <div class="col-3 mt-3">
            <img style="max-height: 100px; max-width: 100%" src="${imgs[10]}">
        </div>
        <div class="col-3 mt-3">
            <img style="max-height: 100px; max-width: 100%" src="${imgs[11]}">
        </div>
        <div class="col-3 mt-3">
            <img style="max-height: 100px; max-width: 100%" src="${imgs[12]}">
        </div>`

        const metricas_relevantes = (flag) => {
            return `<div class="row mt-3 mr-1 ml-1 bg-light"
            style='border: 2px solid lightblue; border-radius: 3px; max-heigth: 200px'>
            <div class="col-12 text-center">
                <h4>Métricas relevantes</h4>
            </div>
            <div class="col-12 pl-3 pr-3">
                <div style="border-bottom: 2px solid lightblue;"></div>
            </div>
            <div class="col-4 text-left pt-2">
                <div style="border-bottom: 2px solid lightblue;">
                    <p class="m-0 text-primary" style="font-weight: bold; font-size: 15px">${$("#title-1")[0].textContent}</p>
                </div>
                ${flag ? `<div>
                    <p style="font-size: 15px">
                        ${$("#desc-1")[0].textContent}
                    </p>
                </div>` : ""}
                <div class="text-center" style="padding-bottom: 10px">
                    <img style="max-height: 250px; max-width: 100%" src="${imgs[13]}">
                </div>
            </div>
            <div class="col-4 text-left pt-2">
                <div style="border-bottom: 2px solid lightblue;">
                    <p class="m-0 text-primary" style="font-weight: bold; font-size: 15px">${$("#title-2")[0].textContent}</p>
                </div>
                ${flag ? `<div>
                    <p style="font-size: 15px">
                        ${$("#desc-2")[0].textContent}
                    </p>
                </div>` : ""}
                <div class="text-center" style="padding-bottom: 10px">
                    <img style="max-height: 250px; max-width: 100%" src="${imgs[14]}">
                </div>
            </div>
            <div class="col-4 text-left pt-2">
                <div style="border-bottom: 2px solid lightblue;">
                    <p class="m-0 text-primary" style="font-weight: bold; font-size: 15px">${$("#title-3")[0].textContent}</p>
                </div>
                ${flag ? `<div>
                    <p style="font-size: 15px">
                        ${$("#desc-3")[0].textContent}
                    </p>
                </div>` : ""}
                <div class="text-center" style="padding-bottom: 10px">
                    <img style="max-height: 250px; max-width: 100%" src="${imgs[15]}">
                </div>
            </div>
            ${""}
        </div>`}

        const mapa_pais = (flag, before = true) => {
            return document.getElementById("mapa_pais").style.display != "none" ?
                `<div class="row mt-3 mr-1 ml-1 bg-light" style='border: 2px solid lightblue; border-radius: 3px; max-heigth: 200px;
                ${before ? "" : ""}'>
            <div class="col-12 text-center">
                <h4>Mapa de calor de México</h4>
            </div>
            <div class="col-12 pl-3 pr-3">
                <div style="border-bottom: 2px solid lightblue;"></div>
            </div>
            ${flag ? `<div class="col-3 vh-100" style="max-height: 500px"><div class="row pt-2" style="height: 100%">${imgs[18]}</div></div>` : ""}
            <div class="col-${flag ? "9" : "12"} text-center mt-2">
                <img width="100%" src="${imgs[16]}">
            </div>
        </div>`
                : ""
        }

        const mapa_mundo = (flag, before = true) => {
            return document.getElementById("mapa_mundo").style.display != "none" ?
                `<div class="row mt-3 mr-1 ml-1 bg-light" style='border: 2px solid lightblue; border-radius: 3px; max-heigth: 200px;
        ${before ? "break-before: page;" : ""}'>
            <div class="col-12 text-center">
                <h4>Mapa de calor mundial</h4>
            </div>
            <div class="col-12 pl-3 pr-3">
                <div style="border-bottom: 2px solid lightblue;"></div>
            </div>
            ${flag ? `<div class="col-3 vh-100" style="max-height: 500px"><div class="row pt-2" style="height: 100%">${imgs[19]}</div></div>` : ""}
            <div class="col-${flag ? "9" : "12"} text-center mt-2">
                <img width="100%" src="${imgs[17]}">
            </div>
        </div>`
                : ""
        }

        const swal = await Swal.fire({
            title: 'Selecciona los elementos incluidos en el reporte',
            html: `<div class="row m-0 p-0">
                <div class="col-9 m-0 p-0 mt-2">
                    <label for="imp_1">Resumen de llamadas, correos, mensajes y formularios</label>
                </div>
                <div class="col-3 m-0 p-0 mt-2">
                    <input id="imp_1" name="imp_1" checked="true" type="checkbox" style="width: 1.5rem; height: 1.5rem;top:0.5rem" />
                </div>

                ${resumen}

                <div class="col-9 m-0 p-0 mt-2">
                    <label for="imp_2">${metricasTxt.slice(0, 3).join(", ") + " y " + metricasTxt[metricasTxt.length - 1]}</label>
                </div>
                <div class="col-3 m-0 p-0 mt-2">
                    <input id="imp_2" name="imp_2" checked="true" type="checkbox" style="width: 1.5rem; height: 1.5rem;top:0.5rem" />
                </div>

                ${resumen_metricas}

                <div class="col-9 m-0 p-0 mt-2">
                    <label for="imp_3">Gráficos de métricas relevantes</label>
                </div>
                <div class="col-3 m-0 p-0 mt-2">
                    <input id="imp_3" name="imp_3" checked="true" type="checkbox" style="width: 1.5rem; height: 1.5rem;top:0.5rem" />
                </div>

                <div class="col-12 m-0 p-0">
                    ${metricas_relevantes(false)}
                </div>

                ${document.getElementById("mapa_pais").style.display != "none"
                    ? `<div class="col-9 m-0 p-0 mt-2">
                    <label for="imp_4">Mapa de calor de México</label>
                </div>
                <div class="col-3 m-0 p-0 mt-2">
                    <input id="imp_4" name="imp_4" checked="true" type="checkbox" style="width: 1.5rem; height: 1.5rem;top:0.5rem" />
                </div>

                <div class="col-12 m-0 p-0">
                    ${mapa_pais(false)}
                </div>` : ""}
                
                ${document.getElementById("mapa_mundo").style.display != "none"
                    ? `<div class="col-9 m-0 p-0 mt-2">
                    <label for="imp_5">Mapa de calor mundial</label>
                </div>
                <div class="col-3 m-0 p-0 mt-2">
                    <input id="imp_5" name="imp_5" checked="true" type="checkbox" style="width: 1.5rem; height: 1.5rem;top:0.5rem" />
                </div>

                <div class="col-12 m-0 p-0">
                    ${mapa_mundo(false)}
                </div>` : ""}
            </div>`,
            preConfirm: function () {
                return new Promise((resolve, reject) => {
                    let els = $("input[id^=imp_]")
                    let payload_prec = {}
                    for (var i = 0; i < els.length; i++) {
                        payload_prec[els[i].id] = els[i].checked
                    }

                    resolve(payload_prec);
                });
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            showCloseButton: true,
            cancelButtonText: `Cancelar`,
        })

        if (!swal.isConfirmed) {
            document.getElementById("loading").classList.add("d-none")
            return
        }

        const content = `<div class="content">
            <div class="mb-3 col-12 text-center">
                <div class="row">
                    <div class="col-12">
                        <div class="row pt-0 pt-md-3 p-3">
                            <div class="col-12 col-md-6 input-group">
                                ${$("#strDate")[0].outerHTML.replace(/className=/g, 'class=')}
                                ${$("#endDate")[0].outerHTML.replace(/className=/g, 'class=')}
                            </div>
                        </div>
                    </div>

                    ${swal.value.imp_1 ? resumen : ""}

                    <div class="col-3">
                    </div>
                    <div class="col-6">
                        <img width="100%" src="${imgs[8]}">
                    </div>
                    <div class="col-3">
                    </div>

                    ${swal.value.imp_2 ? resumen_metricas : ""}
                </div>
                ${swal.value.imp_3 ? metricas_relevantes(true) : ""}
                ${swal.value.imp_4 ? mapa_pais(true, swal.value.imp_3) : ""}
                ${swal.value.imp_5 ? mapa_mundo(true, (swal.value.imp_1 || swal.value.imp_2 || swal.value.imp_3) && (swal.value.imp_4 ? true : false)) : ""}
                
            </div>
        </div>`

        const html1 = `<html>
            <head>
                <style>
                    .content {
                        min-height: calc(100vh - 220px);
                        margin-top: 40px;
                    }
                </style>
                <style>${bootstrap}</style>
            </head>
            <body>
                ${content}
            </body>
        </html>`
        //console.log(html1)
        document.getElementById("loading").classList.add("d-none")
        const letdd = html1
        window.frames["print_frame"].document.body.innerHTML = letdd

        window.frames["print_frame"].focus()

        /*const pdf = new jsPDF(undefined, undefined, undefined, true)
        console.log(document.getElementById("compartir"))
        document.getElementById("compartir").innerHTML = content
        setTimeout(async () => {
            const mapas = await html2canvas(document.getElementById("compartir"))
            console.log(mapas.toDataURL('image/png'))
            pdf.addImage(mapas.toDataURL('image/png'), 'PNG', 0, 10)
            pdf.save("Reporte.pdf")
        }, 500);*/

        setTimeout(() => {
            window.frames["print_frame"].print()
        }, 500);
    }

    const returnFiles = (ids) => {
        return Promise.all(ids.map(async v => {
            const el = await html2canvas(document.querySelector("#" + v))
            if (!el) {
                return null
            }
            const file = await urltoFile(el.toDataURL('image/png'), 'image.png', 'image/png')
            return file
        }))
    }

    const compartir = async (type) => {
        //document.getElementById("loading").classList.remove("d-none")
        //print()
        /*const pastWidth = doc.clientWidth
        console.log(pastWidth)
        doc.style.width = '794px'
        doc.classList.add("p-3")*/

        /*Array.from(doc.getElementsByClassName('notOnDownload')).forEach((value) => {
            value.setAttribute('style', 'display: none;')
        })*/

        if (type == "share") {
            const files = (await returnFiles(["cuadros", "metricas1", "metricas2", "metricas3", "mapa_pais", "mapa_mundo"])).filter(v => v != null)

            if (navigator.canShare && navigator.canShare({ files: [...files] })) {
                navigator.share({
                    files: [...files],
                })
                    .then(() => {
                        /*Array.from(doc.getElementsByClassName('notOnDownload')).forEach((value) => {
                            value.setAttribute('style', 'display: block;')
                        })*/
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } else {
                console.log()
            }
        }
        //document.getElementById("loading").classList.add("d-none")
        /*html2canvas(doc).then(async canvas => {
            if (type == "share") {
                urltoFile(canvas.toDataURL('image/png'), 'image.png', 'image/png').then((value) => {
                    if (navigator.canShare && navigator.canShare({ files: [value] })) {
                        navigator.share({
                            files: [value],
                        })
                            .then(() => {
                                Array.from(doc.getElementsByClassName('notOnDownload')).forEach((value) => {
                                    value.setAttribute('style', 'display: block;')
                                })
                                //doc.style.width = pastWidth + "px"
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                    } else {
                        console.log()
                    }
                })
            }
            if (type == "download") {
                const imgData = canvas.toDataURL('image/png')
                
                const pdf = new jsPDF(undefined, undefined, undefined, true)
                pdf.addImage(imgData, 'PNG', 0, 10)
                pdf.addPage()

                doc.style.width = pastWidth + "px"
                doc = document.querySelector("#mapas")
                doc.style.width = '794px'
                const mapas = await html2canvas(doc)
                pdf.addImage(mapas.toDataURL('image/png'), 'PNG', 0, 10)

                // pdf.output('dataurlnewwindow');
                pdf.save("Reporte.pdf")
                Array.from(doc.getElementsByClassName('hideOnDownload')).forEach((value) => {
                    value.setAttribute('style', 'display: block;')
                })
                doc.style.width = pastWidth + "px"
            }
        })*/
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    useEffect(() => {
        var x = window.matchMedia("(max-width: 768px)")
        if (!x.matches) {
            document.body.classList.toggle('sb-sidenav-toggled')
        }

        //print()
        document.getElementById("barra").style.height = (document.getElementsByClassName("list-group")[0].getBoundingClientRect().height
            + document.getElementById("resDesk").getBoundingClientRect().height) + "px"
    }, [])

    const collapse = () => {
        if (collapsed) {
            setCollapsed(false)
            document.getElementById("navbarSupportedContent").classList.remove("show")
        } else {
            setCollapsed(true)
            document.getElementById("navbarSupportedContent").classList.add("show")
        }
    }

    const toggle = () => {
        if (window.innerWidth >= 768) return
        document.body.classList.toggle('sb-sidenav-toggled');
        if (document.body.classList.contains('sb-sidenav-toggled')) {
            document.getElementById("icon").classList.remove("flecha-reverse-right")
            document.getElementById("icon").classList.add("flecha-reverse")
        } else {
            document.getElementById("icon").classList.remove("flecha-reverse")
            document.getElementById("icon").classList.add("flecha-reverse-right")
        }
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    }
    return (
        <>
            <div className="d-flex" id="wrapper" style={{ background: "white" }}>
                <div id="sidebar-wrapper" style={{ background: "#272855" }}>
                    <div className="sidebar-heading font-weight-bold" id="resDesk"
                        style={{ fontWeight: "bold", cursor: "pointer", background: "#272855", borderBottom: "0px !important" }}
                        onClick={() => handleChangeOfRoute('inicio')}>
                        {/* <i className='icon'></i> */}
                    </div>
                    <div className="list-group list-group-flush" style={{ backgroundColor: "#272855" }}>
                        {/* <div className='d-flex text-center'>
                            <button className="btn text-white" onClick={(event) => { }}
                                style={{ fontSize: "25px" }}>
                                <i className="fa fa-user pr-1 d-block d-md-none" style={{ fontSize: "30px", cursor: "pointer" }}></i>
                            </button>
                            <button className="btn text-white" onClick={(event) => { }}
                                style={{ fontSize: "25px", width: "100%" }}>
                                <i className="fa fa-cog d-block d-md-none" style={{ fontSize: "30px", cursor: "pointer" }}></i>
                            </button>
                        </div> */}

                        <div className='d-flex d-block d-md-none'>
                            <button className="btn text-white" onClick={(event) => { }}
                                style={{ fontSize: "25px", width: "50%" }}>
                                <i className="fa fa-user"></i>
                            </button>
                            <button className="btn text-white" onClick={(event) => { }}
                                style={{ fontSize: "25px", width: "50%" }}>
                                <i className="fa fa-cog"></i>
                            </button>
                            <button className="btn text-white" onClick={(event) => { handleLogout() }}
                                style={{ fontSize: "25px", width: "50%" }}>
                                <i className="fa fa-sign-out"></i>
                            </button>
                        </div>

                        <a className="list-group-item-action list-group-item-light pl-3 pr-3 pb-2 hideSEC"
                            style={{ fontWeight: "bold", background: "transparent", cursor: "pointer" }}
                            onClick={() => {
                                handleChangeOfRoute('seccion')
                                toggle()
                            }} id="secDesk">
                            <div style={{ borderRadius: "5px", background: "white", textAlign: "center", padding: "10px" }}>
                                <img width="80%" src={seccion} />
                            </div>
                        </a>
                        <a className="list-group-item-action list-group-item-light pl-3 pr-3 pb-2 hideGA"
                            style={{ fontWeight: "bold", background: "transparent", cursor: "pointer" }}
                            onClick={() => {
                                handleChangeOfRoute('sitioadn')
                                toggle()
                            }} id="gaDesk">
                            <div style={{ borderRadius: "5px", background: "white", textAlign: "center", padding: "10px" }}>
                                <img width="80%" src={adnlogo} />
                            </div>
                        </a>
                        <a className="list-group-item-action list-group-item-light pl-3 pr-3 pb-2 hidePLS"
                            style={{ fontWeight: "bold", background: "transparent", cursor: "pointer" }}
                            onClick={() => {
                                handleChangeOfRoute('adnpro')
                                toggle()
                            }} id="plsDesk">
                            <div style={{ borderRadius: "5px", background: "white", textAlign: "center", padding: "10px" }}>
                                <img width="80%" src={andplus} />
                            </div>
                        </a>
                        <a className="list-group-item-action list-group-item-light pl-3 pr-3 pb-2 hideFB"
                            style={{ fontWeight: "bold", background: "transparent", cursor: "pointer" }}
                            onClick={() => {
                                handleChangeOfRoute('facebook')
                                toggle()
                            }} id="faceDesk">
                            <div style={{ borderRadius: "5px", background: "white", textAlign: "center", padding: "10px" }}>
                                <img width="80%" src={facebook} />
                            </div>
                        </a>
                        <a className="list-group-item-action list-group-item-light pl-3 pr-3 pb-2 hideFBA"
                            style={{ fontWeight: "bold", background: "transparent", cursor: "pointer" }}
                            onClick={() => {
                                handleChangeOfRoute('fbads')
                                toggle()
                            }} id="fbaDesk">
                            <div style={{ borderRadius: "5px", background: "white", textAlign: "center", padding: "10px" }}>
                                <img width="80%" src={facebook_ads} />
                            </div>
                        </a>
                        <a className="list-group-item-action list-group-item-light pl-3 pr-3 pb-2 hideADS"
                            style={{ fontWeight: "bold", background: "transparent", cursor: "pointer" }}
                            onClick={() => {
                                handleChangeOfRoute('googleads')
                                toggle()
                            }} id="adsDesk">
                            <div style={{ borderRadius: "5px", background: "white", textAlign: "center", padding: "10px" }}>
                                <img width="80%" src={gads} />
                            </div>
                        </a>
                        <a className="list-group-item-action list-group-item-light pl-3 pr-3 pb-2 hideSRC"
                            style={{ fontWeight: "bold", background: "transparent", cursor: "pointer" }}
                            onClick={() => {
                                handleChangeOfRoute('search')
                                toggle()
                            }} id="srcDesk">
                            <div style={{ borderRadius: "5px", background: "white", textAlign: "center", padding: "10px" }}>
                                <img width="80%" src={searchlogo} />
                            </div>
                        </a>
                        <a className="list-group-item-action list-group-item-light pl-3 pr-3 pb-2 hideIA"
                            style={{ fontWeight: "bold", background: "transparent", cursor: "pointer" }}
                            onClick={() => {
                                handleChangeOfRoute('coach')
                                toggle()
                            }} id="iaDesk">
                            <div style={{ borderRadius: "5px", background: "white", textAlign: "center", padding: "10px" }}>
                                <p className='h5'>Coach Digital <img width={"15%"} src={ialogo} /></p>
                            </div>
                        </a>
                        <div className='d-flex d-block d-md-none'>
                            <button className="btn text-white" onClick={(event) => { }}
                                style={{ fontSize: "25px", width: "100%" }}>
                                <i className="fa fa-at"></i>
                            </button>
                            <button className="btn text-white" onClick={(event) => {
                                compartir("share")
                                toggle()
                            }}
                                style={{ fontSize: "25px" }}>
                                <i className="whatsapp-icon"></i>
                            </button>
                            <button className="btn text-white" onClick={(event) => {
                                print()
                                toggle()
                            }}
                                style={{ fontSize: "25px", width: "100%" }}>
                                <i className="fa fa-download"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="barra" style={{
                    background: "#622563", width: "fit-content",
                    borderBottomRightRadius: "30px", borderTopRightRadius: "30px", display: "flex", flexDirection: "column"
                }}>
                    {/* <div style={{ display: "table-cell", verticalAlign: "middle", maxWidth: "fit-content" }}>
                        
                        <button className="btn text-white d-none d-md-block pt-3" id="sidebarToggle" onClick={(event) => {
                            event.preventDefault();
                            document.body.classList.toggle('sb-sidenav-toggled');
                            if (document.body.classList.contains('sb-sidenav-toggled')) {
                                document.getElementById("icon2").classList.remove("flecha-left")
                                document.getElementById("icon2").classList.add("flecha")
                            } else {
                                document.getElementById("icon2").classList.remove("flecha")
                                document.getElementById("icon2").classList.add("flecha-left")
                            }
                            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
                        }}>
                            <i id="icon2" className="flecha-left"></i>
                        </button>
                        
                    </div> */}
                    <div style={{ marginBottom: "auto" }}>
                        <button className="btn text-white d-none d-md-block pt-3" onClick={(event) => { }}
                            style={{ fontSize: "25px" }}>
                            <i className="adn-icon"></i>
                        </button>
                    </div>
                    <div style={{ margin: "0" }}>
                        <button className="btn text-white d-none d-md-block" id="sidebarToggle" onClick={(event) => {
                            event.preventDefault();
                            document.body.classList.toggle('sb-sidenav-toggled');
                            if (document.body.classList.contains('sb-sidenav-toggled')) {
                                document.getElementById("icon2").classList.remove("flecha-left")
                                document.getElementById("icon2").classList.add("flecha")
                            } else {
                                document.getElementById("icon2").classList.remove("flecha")
                                document.getElementById("icon2").classList.add("flecha-left")
                            }
                            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
                        }}>
                            <i id="icon2" className="flecha"></i>
                        </button>
                    </div>
                    <div style={{ marginTop: "auto" }}>
                        <button className="btn text-white d-none d-md-block" onClick={(event) => { }}
                            style={{ fontSize: "25px", width: "100%" }}>
                            <i className="fa fa-at"></i>
                        </button>
                        <button className="btn text-white d-none d-md-block" onClick={(event) => {
                            compartir("share")
                        }}
                            style={{ fontSize: "25px" }}>
                            <i className="whatsapp-icon"></i>
                        </button>
                        <button className="btn text-white d-none d-md-block" onClick={(event) => {
                            print()
                        }}
                            style={{ fontSize: "25px", width: "100%" }}>
                            <i className="fa fa-download"></i>
                        </button>
                    </div>
                </div>
                <div id="page-content-wrapper">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                        <div className="container-fluid">
                            <a className="d-block d-md-none" id="sidebarToggle" onClick={(event) => {
                                event.preventDefault();
                                document.body.classList.toggle('sb-sidenav-toggled');
                                if (document.body.classList.contains('sb-sidenav-toggled')) {
                                    document.getElementById("icon").classList.remove("flecha-reverse-right")
                                    document.getElementById("icon").classList.add("flecha-reverse")
                                } else {
                                    document.getElementById("icon").classList.remove("flecha-reverse")
                                    document.getElementById("icon").classList.add("flecha-reverse-right")
                                }
                                localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
                            }}>
                                <i id="icon" className="flecha-reverse-right"></i>
                            </a>
                            {/* <button className="navbar-toggler btn-sm d-block d-md-none" id="collapseMob" type="button"
                                data-bs-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation"
                                onClick={() => {
                                    collapse()
                                }}>
                                <span className="navbar-toggler-icon" style={{ fontSize: "18px" }}></span>
                            </button> */}

                            <img className="d-block d-md-none" onClick={() => {
                                handleChangeOfRoute('inicio')
                            }}
                                width="40%" src={minegocio} />

                            <img className="d-none d-md-block" width="15%" style={{ cursor: "pointer" }}
                                src={minegocio} onClick={() => handleChangeOfRoute('inicio')} />

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto mt-2 mt-lg-0 d-block d-md-none">
                                    <li className="nav-item active border-bottom">
                                        <a className="nav-link" onClick={() => handleChangeOfRoute('inicio')}>
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={minegocio} />
                                        </a>
                                    </li>
                                    <li className="nav-item hideSEC border-bottom">
                                        <a className="nav-link" onClick={() => handleChangeOfRoute('seccion')}>
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={seccion} />
                                        </a>
                                    </li>
                                    <li className="nav-item hideGA border-bottom">
                                        <a className="nav-link" onClick={() => handleChangeOfRoute('sitioadn')}>
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={adnlogo} />
                                        </a>
                                    </li>
                                    <li className="nav-item hidePLS border-bottom">
                                        <a className="nav-link" onClick={() => handleChangeOfRoute('adnpro')}>
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={andplus} />
                                        </a>
                                    </li>
                                    <li className="nav-item hideFB border-bottom">
                                        <a className="nav-link" onClick={() => handleChangeOfRoute('facebook')}>
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={facebook} />
                                        </a>
                                    </li>
                                    <li className="nav-item hideFB border-bottom">
                                        <a className="nav-link" onClick={() => handleChangeOfRoute('fbads')}>
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={facebook_ads} />
                                        </a>
                                    </li>
                                    <li className="nav-item hideADS border-bottom">
                                        <a className="nav-link" onClick={() => handleChangeOfRoute('googleads')}>
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={gads} />
                                        </a>
                                    </li>
                                    <li className="nav-item hideSRC border-bottom">
                                        <a className="nav-link" onClick={() => handleChangeOfRoute('search')}>
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={searchlogo} />
                                        </a>
                                    </li>
                                    <li className="nav-item hideIA border-bottom">
                                        <a className="nav-link font-weight-bold" onClick={() => handleChangeOfRoute('coach')}>
                                            Coach Digital &nbsp;
                                            <img style={{ maxHeight: "30px", maxWidth: "35%" }}
                                                src={ialogo} />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className='d-none d-md-block' style={{ display: "flex" }}>
                                <i className="fa fa-user pr-3" style={{ fontSize: "30px", color: "#731e67", cursor: "pointer" }}></i>

                                <i className="fa fa-cog pr-3" style={{ fontSize: "30px", color: "#731e67", cursor: "pointer" }}></i>

                                <i className="fa fa-sign-out" onClick={() => {
                                    handleLogout()
                                }} style={{ fontSize: "30px", color: "#731e67", cursor: "pointer" }}></i>
                            </div>

                            <button className="btn text-white d-block d-md-none" onClick={(event) => {
                                compartir("share")
                            }}
                                style={{ fontSize: "25px" }}>
                                <i className="whatsapp-icon"></i>
                            </button>
                        </div>
                    </nav>
                    <div className="container-fluid p-0">
                        {
                            !reloading &&
                            <>
                                {
                                    (page == 'inicio') &&
                                    <InicioScreen />
                                }
                                {
                                    (page == 'seccion') &&
                                    <SeccionScreen />
                                }
                                {
                                    (page == 'sitioadn') &&
                                    <SitioAdnScreen />
                                }
                                {
                                    (page == 'facebook') &&
                                    <FacebookScreen />
                                }
                                {
                                    (page == 'googleads') &&
                                    <AdsScreen />
                                }
                                {
                                    (page == 'adnpro') &&
                                    <AdnProScreen />
                                }
                                {
                                    (page == 'search') &&
                                    <SearchScreen />
                                }
                                {
                                    (page == 'coach') &&
                                    <CoachScreen />
                                }
                                {
                                    (page == 'fbads') &&
                                    <FacebookAdsScreen />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            {/* <Navbar expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link onClick={() => handleChangeOfRoute('inicio')}>
                                <span className="text-light">Registros <i className="fas fa-tasks"></i></span>
                            </Nav.Link>
                        </Nav>
                        {["1", "2", "3"].includes(localStorage.getItem("idRol")) &&
                            <Nav>
                                <Nav.Link onClick={() => handleChangeOfRoute('portadas')}>
                                    <span className="text-light">Facebook <i className="fas fa-clipboard-list"></i></span>
                                </Nav.Link>
                            </Nav>
                        }
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <Nav.Link onClick={handleLogout}>
                                <span className="text-light">Salir </span>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}
        </>
    )
}
