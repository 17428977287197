import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    startLoginEmailPassword
} from '../../actions/auth';
import logo from '../../imgs/logo.png'
import minegocio from '../../imgs/logo_mi_negocio.png'
import portadaini from '../../imgs/portada-ini.png'
import recurso7 from '../../imgs/recurso-7.png'
import imgini2 from '../../imgs/img-ini-2.png'

export const LoginScreen = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui);
    const [passShowedLogin, setPassShowedLogin] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(startLoginEmailPassword(email, password));
    }

    useEffect(() => {
    }, [])

    return (
        <>
            {/* <div className='row login-header' style={{ minHeight: "400px", padding: "140px 0" }}>
                <div className='col-12 col-md-3 ml-6'>
                    <p>Bienvenido</p><br/>
                    <p>a tu portal integral de estrategia digital.</p>
                </div>
                <div className='col-12 col-9'></div>
            </div> */}
            <div className='container-login'>
                <div className="tag-login" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                    <p className="h2 text-primary" style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: "bolder" }}>Bienvenido</p>
                    <p className="h3" style={{ fontFamily: 'Montserrat, sans-serif' }}>a tu portal integral</p>
                    <p className="h3" style={{ fontFamily: 'Montserrat, sans-serif' }}>de estrategia digital.</p>
                </div>
                <img src={portadaini} width={"100%"} />
            </div>
            <div className='row bg-white p-3 pl-4'>
                <div className="col-12 m-0 p-0 mt-1 pt-1 text-center notOnDownload">
                    <p>Conoce estrategias para maximizar tu crecimiento
                        <button style={{ borderRadius: "5px", marginLeft: "10px", borderColor: "lightblue" }}> Contactar a un especialista</button>
                    </p>
                </div>
                <div className='col-12 col-md-8 text-white' style={{ borderRadius: "10px", background: "#4D6883" }}>
                    <p className="h5 pt-5" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        <strong>Bienvenido al Portal Mi negocio
                            tu herraminenta de Gestión de SEO y Marketing Digital</strong>
                    </p>
                    <p className="pt-3" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        En nuestro portal, encontrarás todo lo que necesitas para potenciar tu presencia online
                        y alcanzar tus objetivos de marketing digital. Desde estrategias de SEO hasta tácticas
                        de marketing en redes sociales, estamos aquí para brindarte las herramientas y
                        conocimientos necesarios para destacarte en el mundo digital.
                    </p>
                </div>
                <div className='col-12 col-md-4 text-center'>
                    <div className='pt-3 mt-3'>
                        <img src={minegocio} className="img-fluid"
                            alt="Task logo" width="70%" style={{ borderRadius: "10px" }} />
                    </div>
                    <form onSubmit={handleLogin}>
                        <div className="form-outline mt-4 mb-4 text-center">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"
                                        style={{ color: 'white', background: '#1d3b55', borderColor: '#1d3b55' }}>
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                                <input type="text"
                                    placeholder="Correo"
                                    name="email"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                    className="form-control form-control-lg"
                                    autoComplete="off"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)} />
                            </div>

                        </div>
                        <div className="form-outline mb-3 text-center">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"
                                        style={{ color: 'white', background: '#1d3b55', borderColor: '#1d3b55' }}>
                                        <i className="fas fa-key"></i>
                                    </span>
                                </div>
                                <input
                                    type={passShowedLogin ? "text" : "password"}
                                    placeholder="Contraseña"
                                    name="password"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                    className="form-control form-control-lg"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)} />
                                <div className="input-group-append">
                                    <button className="btn" type="button"
                                        style={{ color: 'white', background: '#1d3b55', borderColor: '#1d3b55' }}
                                        onClick={() => setPassShowedLogin(!passShowedLogin)}>
                                        <i className={passShowedLogin ? "fa fa-eye-slash" : "fa fa-eye"}
                                            aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="text-center text-lg-start mt-4 mb-2 pt-2">
                            <button type="submit" className="btn btn-primary btn-large" disabled={loading}
                                style={{
                                    'paddingLeft': '2.5rem', 'paddingRight': '2.5rem', fontSize: '18px',
                                    color: 'white', background: '#1d3b55', borderColor: '#1d3b55'
                                }}>
                                Iniciar sesión
                            </button>
                        </div>
                    </form>
                </div>
                <div className='col-12 col-md-6 pt-3 border-right'>
                    <p className="h5 text-primary pb-2" style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: "bold" }}>
                        SEO Power:<br />
                        Potencia tu Presencia Online<br />
                        con Estrategias de Posicionamiento
                    </p>
                    <div className='mr-2 ml-2 pb-2' style={{ borderTop: "1px solid black" }}></div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris.
                    </p>
                    <img src={recurso7} width="100%"/>
                </div>
                <div className='col-12 col-md-6 pt-3 border-left'>
                    <p className="h5 text-primary pb-2" style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: "bold" }}>
                        Social Media Pro:<br />
                        Asesoria en Estrategias más Efectivas<br />
                        <p></p>
                    </p>
                    <div className='mr-2 ml-2 pb-2' style={{ borderTop: "1px solid black" }}></div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris.
                    </p>
                    <img src={imgini2} width="100%"/>
                </div>
            </div>
            {/* <div className="container-fluid h-100" style={{ display: "flex", flexDirection: "column" }}>
                <div className="row text-center login-page" style={{ margin: "0" }}>
                    <div className="col-12 login-form">
                        <div className="row">
                            <div className="col-12 col-md-6 login-form-header"></div>
                            <div className='col-12 col-md-6'>
                                <div className='pt-3 mt-3'>
                                    <img src={minegocio} className="img-fluid"
                                        alt="Task logo" width="70%" style={{ borderRadius: "10px" }} />
                                </div>
                                <form onSubmit={handleLogin}>
                                    <div className="form-outline mt-4 mb-4 text-center">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"
                                                    style={{ color: 'white', background: '#1d3b55', borderColor: '#1d3b55' }}>
                                                    <i className="fas fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text"
                                                placeholder="Correo"
                                                name="email"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                className="form-control form-control-lg"
                                                autoComplete="off"
                                                value={email}
                                                onChange={e => setEmail(e.target.value)} />
                                        </div>

                                    </div>
                                    <div className="form-outline mb-3 text-center">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"
                                                    style={{ color: 'white', background: '#1d3b55', borderColor: '#1d3b55' }}>
                                                    <i className="fas fa-key"></i>
                                                </span>
                                            </div>
                                            <input
                                                type={passShowedLogin ? "text" : "password"}
                                                placeholder="Contraseña"
                                                name="password"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                className="form-control form-control-lg"
                                                value={password}
                                                onChange={e => setPassword(e.target.value)} />
                                            <div className="input-group-append">
                                                <button className="btn" type="button"
                                                    style={{ color: 'white', background: '#1d3b55', borderColor: '#1d3b55' }}
                                                    onClick={() => setPassShowedLogin(!passShowedLogin)}>
                                                    <i className={passShowedLogin ? "fa fa-eye-slash" : "fa fa-eye"}
                                                        aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center text-lg-start mt-4 mb-2 pt-2">
                                        <button type="submit" className="btn btn-primary btn-large" disabled={loading}
                                            style={{
                                                'paddingLeft': '2.5rem', 'paddingRight': '2.5rem', fontSize: '18px',
                                                color: 'white', background: '#1d3b55', borderColor: '#1d3b55'
                                            }}>
                                            Iniciar sesión
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
        </>
    )
}