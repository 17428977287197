import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { detectMob, loadQueryString, numberWithCommas, toMillions, makeGET, makePOST, returnNumber } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import $ from 'jquery'
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { createFilter } from "react-select";
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'
import fb from '../../imgs/facebook-ads.svg'
import gusta from '../../imgs/gusta.png'
import encanta from '../../imgs/encanta.png'
import asombra from '../../imgs/asombra.png'
import divierte from '../../imgs/divierte.png'
import triste from '../../imgs/triste.png'
import enoja from '../../imgs/enoja.png'

$.DataTable = require('datatables.net')
require("datatables.net-responsive");

export const FacebookAdsScreen = () => {
    const user = useSelector((state) => state.auth);
    useEffect(() => {
        var x = new Date()

        var d = new Date(x.getFullYear(), x.getMonth() - 6, 1)
        x.setDate(0)
        var d1 = x
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("value", moment(d).format("YYYY-MM-DD"))
        strDate.setAttribute("max", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("value", moment(d1).format("YYYY-MM-DD"))
        endDate.setAttribute("min", moment(d).format("YYYY-MM-DD"))

        getDatos();
    }, []);

    const [datos, setDatos] = useState([]);
    const [page, setPage] = useState("");
    const [pageData, setPageData] = useState({ label: "TODOS", value: "TODOS" });
    const [dataSesiones, setDataSesiones] = useState(undefined);

    const [searches, setSearches] = useState([])
    let options = {
        legend: { display: true },
        elements: {
            line: {
                tension: 0
            }
        },
        animation: false,
        scales: {
            yAxes: {
                display: true,
                ticks: {
                    beginAtZero: true
                }
            }
        }
    }

    const [searchTable, setSearchTable] = useState(undefined)

    useEffect(() => {
        if (page != "") {
            getDatos()
        }
    }, [page])

    const getDatos = async () => {
        let hostname = "", pageTmp = ((page != "" && page != "TODOS") ? page : "")
        const isMobile = detectMob()
        document.getElementById("loading").classList.remove("d-none")
        const params = loadQueryString();

        if (params.page) {
            pageTmp = params.page
            document.getElementById("hosts").style.display = "none"
        }

        const sampleUrl = "files_protected/samplefbads.json"
        const url = "report/fbads?" + "dateStr="
            + document.getElementById("strDate").value + "&dateEnd=" + document.getElementById("endDate").value
            + (pageTmp != "" ? "&page=" + pageTmp : "")

        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)
        makeGET(params.test ? sampleUrl : url).then(({ data }) => {
            document.getElementById("loading").classList.add("d-none")
            $("#page_views").text(returnNumber(data.totals.page_views))
            $("#follows").text(((data.totals.clics / data.totals.page_imps) * 100).toFixed(2) + "%")
            $("#page_imps").text(returnNumber(data.totals.page_imps_paid))
            $("#fan_adds").text(((data.totals.engaged_users / data.totals.page_imps) * 100).toFixed(2) + "%")

            $("#video").text(returnNumber(data.totals.avg_video))
            $("#impresiones_o").text(returnNumber(data.totals.page_imps_organic))
            $("#clics").text(returnNumber(data.totals.clics))
            $("#engaged").text(returnNumber(data.totals.engaged_users))

            data.months.sort((a, b) => (new Date(a.month) > new Date(b.month)) ? 1 : ((new Date(b.month) > new Date(a.month)) ? -1 : 0))

            const monthsParsed = data.months.map(v => v.monthParsed)
            const monthsPageViews = data.months.map(v => v.page_views || 0)
            const monthsImpPaid = data.months.map(v => v.page_imps_paid || 0)
            const monthsImpOrganic = data.months.map(v => v.page_imps_organic || 0)
            /*if (isMobile) {
              document.getElementById("sesionesBar1").style.height = (document.getElementById("sesionesBar1").offsetWidth - 100) + "px"
            }
            if (sesionesChart1) {
              sesionesChart1.destroy()
            }*/
            setDataSesiones({
                labels: monthsParsed,
                datasets: [{
                    label: "Vistas de una página",
                    type: "line",
                    borderColor: "#0097a7",
                    backgroundColor: "#0097a7",
                    data: monthsPageViews,
                    fill: false
                },
                {
                    label: "Impresiones pagadas",
                    type: "line",
                    borderColor: "#58ac3f",
                    backgroundColor: "#58ac3f",
                    data: monthsImpPaid,
                    fill: false
                }
                ]
            })

            setDatos({ ...data })

            if (searchTable) {
                searchTable.clear()
                searchTable.destroy()
            }

            data.rows = data.rows.map(v => {
                return {
                    ...v,
                    page_imps_p: numberWithCommas(v.page_imps_paid || "0"),
                    total_clics: numberWithCommas(v.clics || "0")
                }
            })

            setSearchTable($(`#eventosFB`).DataTable(
                {
                    searching: false, info: false, "dom": 'rtip',
                    order: [[4, 'desc']],
                    "responsive": true,
                    data: data.rows,
                    columns: [
                        { "data": "name" },
                        { "data": "n_posts" },
                        { "data": "date_month" },
                        { "data": "page_imps_p" },
                        { "data": "total_clics" },
                    ]
                }
            ))
            setSearches([{ label: "TODOS", value: "TODOS" }, ...data.pages.map(v => {
                return {
                    label: v, value: data.rows.find(p => p.name == v)?.id_page
                }
            })])

            /*if (pageTmp == "") {
                setSearches(data.pages.map(v => {
                    return {
                        label: v, value: data.rows.find(p => p.name == v)?.id_page
                    }
                }))
            }*/
            setDateChanged(false)
        }).catch((e) => {
            document.getElementById("loading").classList.add("d-none")
        })
    };

    const [showOptions, setShowOptions] = useState(false);
    const handleInputChange = useCallback((typedOption) => {
        if (typedOption.length > 2) {
            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }
    }, []);

    const [dateChanged, setDateChanged] = useState(false)
    const submitDate = () => {
        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
        strDate.setAttribute("max", endDate.value)
        endDate.setAttribute("min", strDate.value)
        setDateChanged(true)
    }
    return (
        <div className="">
            <div className="loading d-none" id="loading">
                <Spinner animation="border" role="status"></Spinner>
            </div>
            <div id="firstRow" className="row pr-3 pl-3">
                <div className="col-12 col-md-4 pb-3 pt-3 pl-3 text-center">
                    <img width="60%" className="" src={fb} />
                </div>
                <div className="col-12 col-md-4">
                    <Select
                        options={searches}
                        autoFocus={false}
                        className="pt-3"
                        id="hosts"
                        style={{ width: "100%", paddingTop: "1rem!important" }}
                        isSearchable={true}
                        value={pageData}
                        onInputChange={handleInputChange}
                        onChange={(e) => {
                            setPage(e.value)
                            setPageData(e)
                        }}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <div className="row pt-0 pt-md-3 p-3">
                        <div className="input-group">
                            <input type="date" onChange={submitDate} id="strDate" className="form-control" />
                            <input type="date" onChange={submitDate} id="endDate" className="form-control" />
                            <button disabled={!dateChanged} onClick={getDatos} className="btn btn-primary">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>

                {/* <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">Vistas de página</p>
                    <h5 className="card-text mb-3" id="page_views">-</h5>
                </div>
                <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">Impresiones p.</p>
                    <h5 className="card-text mb-3" id="page_imps">-</h5>
                </div>
                <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">CTR</p>
                    <h5 className="card-text mb-3" id="follows">-</h5>
                </div>
                <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">ER</p>
                    <h5 className="card-text mb-3" id="fan_adds">-</h5>
                </div> */}

                <div className="col-md-3 col-6">
                    <div className="card text-white bg-primary mb-3 text-center" style={{ maxWidth: "18rem" }}>
                        <div className="card-body">
                            <h5 className="card-title">Vistas de página <i className="fa fa-eye"></i></h5>
                            <h4 className="card-text" id="page_views">-</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="card text-white bg-success mb-3 text-center" style={{ maxWidth: "18rem" }}>
                        <div className="card-body">
                            <h5 className="card-title">Impresiones p.<i className="fa fa-bullhorn"></i></h5>
                            <h4 className="card-text" id="page_imps">-</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="card text-white bg-info mb-3 text-center" style={{ maxWidth: "18rem" }}>
                        <div className="card-body">
                            <h5 className="card-title">CTR <i className="fa fa-percent"></i></h5>
                            <h4 className="card-text" id="follows">-</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="card text-white bg-danger mb-3 text-center" style={{ maxWidth: "18rem" }}>
                        <div className="card-body">
                            <h5 className="card-title">ER <i className="fa fa-user-plus"></i></h5>
                            <h4 className="card-text" id="fan_adds">-</h4>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-6">
                    <div className="card text-white mb-3 text-center" style={{ maxWidth: "18rem", backgroundColor: "#5D3587" }}>
                        <div className="card-body">
                            <h5 className="card-title">Vistas de vídeo p. <i className="fa fa-video-camera"></i></h5>
                            <h4 className="card-text" id="video">-</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="card text-white mb-3 text-center" style={{ maxWidth: "18rem", backgroundColor: "#5F0F40" }}>
                        <div className="card-body">
                            <h5 className="card-title">Impresiones o. <i className="fa fa-bullhorn"></i></h5>
                            <h4 className="card-text" id="impresiones_o">-</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="card text-white mb-3 text-center" style={{ maxWidth: "18rem", backgroundColor: "#637E76" }}>
                        <div className="card-body">
                            <h5 className="card-title">Clics <i className="fa fa-mouse-pointer"></i></h5>
                            <h4 className="card-text" id="clics">-</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="card text-white mb-3 text-center" style={{ maxWidth: "18rem", backgroundColor: "#3081D0" }}>
                        <div className="card-body">
                            <h5 className="card-title">Engaged users <i className="fa fa-user"></i></h5>
                            <h4 className="card-text" id="engaged">-</h4>
                        </div>
                    </div>
                </div>

                {/* <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">Vistas de vídeo p.</p>
                    <h5 className="card-text mb-3" id="video">-</h5>
                </div>
                <div className="col-md-3 col-4 text-center">
                    <p className="card-title font-weight-bold">Impresiones o.</p>
                    <h5 className="card-text mb-3" id="impresiones_o">-</h5>
                </div>
                <div className="col-md-3 col-6 text-center">
                    <p className="card-title font-weight-bold">Clics</p>
                    <h5 className="card-text mb-3" id="clics">-</h5>
                </div>
                <div className="col-md-3 col-6 text-center">
                    <p className="card-title font-weight-bold">Engaged users</p>
                    <h5 className="card-text mb-3" id="engaged">-</h5>
                </div> */}

                <div className="col-12 mt-3 p-3">
                    {dataSesiones && <Bar options={options} data={dataSesiones} />}
                </div>
                <div className="col-12">
                    <table id="eventosFB" className="display" style={{ width: "100%", maxWidth: "100%" }}>
                        <thead style={{ backgroundColor: "#58ac3f", color: "white" }}>
                            <tr>
                                <th>Página</th>
                                <th>Posts</th>
                                <th>Mes</th>
                                <th>Impresiones p.</th>
                                <th>Clics</th>
                            </tr>
                        </thead>
                        <tbody id="eventosBody"></tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
