import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { detectMob, loadQueryString, numberWithCommas, toMillions, makeGET, makePOST, returnNumber } from "../../helpers/makeRequest";
import 'react-phone-number-input/style.css'
import moment from 'moment';
import $ from 'jquery'
import Spinner from 'react-bootstrap/Spinner';
import { useParams, useHistory } from 'react-router';
import seccion from '../../imgs/seccion_amarilla.png'
import adnlogo from '../../imgs/adn_logo.png'
import andplus from '../../imgs/logo-ADN-plus.png'
import facebook from '../../imgs/facebook_logo.png'
import divierte from '../../imgs/divierte.png'
import gads from '../../imgs/google_ads.png'
import searchlogo from '../../imgs/search_console.png'
import fbads from '../../imgs/facebook-ads.svg'
import correos from '../../imgs/correos.png'
import llamadas from '../../imgs/llamadas.png'
import formas from '../../imgs/formas.png'
import whatsapp_img from '../../imgs/whatsap-fondo.png'
import estadistica from '../../imgs/estadistica.png'
import { Bar, Pie, PolarArea } from 'react-chartjs-2';
import 'chart.js/auto'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WorldMap from "react-svg-worldmap";
import iso from 'iso-3166-1'
import { getCountry } from 'country-list-spanish'
//import Stories from 'stories-react';
//import 'stories-react/dist/index.css';
import Stories from 'react-insta-stories';
import Mexico from "@svg-maps/mexico";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import { Tooltip } from 'react-tooltip'
import Select from "react-select";

$.DataTable = require('datatables.net')
require("datatables.net-responsive");

export const InicioScreen = () => {
  const user = useSelector((state) => state.auth);
  const [dateChanged, setDateChanged] = useState(false)

  useEffect(() => {
    var x = new Date()

    var d = new Date(x.getFullYear(), x.getMonth() - 1, 1)
    x.setDate(0)
    var d1 = x

    setCurrentMonth(mapMonths[d1.getMonth()])

    let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
    strDate.setAttribute("value", moment(d).format("YYYY-MM-DD"))
    strDate.setAttribute("max", moment(d1).format("YYYY-MM-DD"))
    endDate.setAttribute("value", moment(d1).format("YYYY-MM-DD"))
    endDate.setAttribute("min", moment(d).format("YYYY-MM-DD"))
    getDatos(currentData);
  }, []);

  const handleChangeOfRoute = (route) => {
    setPage(route)
    history.push('/' + route)
  }
  const history = useHistory()

  const hideEls = (key) => {
    var els = document.getElementsByClassName("hide" + key)
    for (var i = 0; i < els.length; i++) {
      els[i].style.display = "none"
      els[i].classList.remove("d-md-block")
      els[i].classList.remove("d-block")
    }
  }

  const mapMonths = ["enero", "febrero", "marzo", "abril", "mayo",
    "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre",
  ]

  const [currentMonth, setCurrentMonth] = useState(undefined);

  const [datos, setDatos] = useState([]);
  const [page, setPage] = useState([]);
  const [hostname, setHostname] = useState([]);
  const [search, setSearch] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [fuentes, setFuentes] = useState([
    { value: "hideGA", label: <div className="text-center p-3"><img style={{ width: "40%" }} src={adnlogo}></img></div> },
    { value: "hideSEC", label: <div className="text-center p-3"><img style={{ width: "40%" }} src={seccion}></img></div> },
    { value: "hidePLS", label: <div className="text-center p-3"><img style={{ width: "40%" }} src={andplus}></img></div> },
    { value: "hideFB", label: <div className="text-center p-3"><img style={{ width: "40%" }} src={facebook}></img></div> },
    { value: "hideFBA", label: <div className="text-center p-3"><img style={{ width: "40%" }} src={fbads}></img></div> },
    { value: "hideADS", label: <div className="text-center p-3"><img style={{ width: "40%" }} src={gads}></img></div> },
    { value: "hideSRC", label: <div className="text-center p-3"><img style={{ width: "40%" }} src={searchlogo}></img></div> },
  ])
  const [currentData, setCurrentData] = useState("hideGA");
  const [currentDataDesc, setCurrentDataDesc] = useState({});

  const getDatos = async (source = "hideGA") => {
    document.getElementById("loading").classList.remove("d-none")
    const isMobile = detectMob()
    const host = window.location.protocol.startsWith("https") ? ("https://" + window.location.host) : "http://localhost:8000"
    document.getElementById("loading").classList.remove("d-none")
    const params = loadQueryString();
    console.log(params)
    if (params.page) {
      setPage(params.page)
    }

    if (params.hostname) {
      setHostname(params.hostname)
    }

    if (params.search) {
      setSearch(params.search)
    }

    if (params.campaign) {
      setCampaign(params.campaign)
    }

    let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
    strDate.setAttribute("max", endDate.value)
    endDate.setAttribute("min", strDate.value)

    const canalesMap = {
      email: "email",
      "cta:tel": "llamada",
      outbound: "whatsapp",
      form: "formulario",
      "cta:mailto": "email",
      whatapp: "whatsapp",
      "cta:whatsapp": "whatsapp",
      "cta:avaya-agent-call": "llamada"
    }
    const canales = ["llamada", "email", "whatsapp", "formulario"]

    makeGET(params.test ? "files_protected/sampleinicio2.json" : "report/summaryModular?dateStr=" + document.getElementById("strDate").value
      + "&dateEnd=" + document.getElementById("endDate").value + "&source=" + source.replace("hide", "")
      + (params.page ? "&page=" + params.page : "")
      + (params.hostname ? "&hostname=" + params.hostname : "")
      + (params.campaign ? "&campaign=" + params.campaign : "")
      + (params.search ? "&search=" + (params.search).replace(/ /g, '-') : "")).then(({ data }) => {

        document.getElementById("loading").classList.add("d-none")
        /*const table = $(`#table1`).DataTable(
          {
            searching: false, info: false, "dom": 'rtip',
            order: [[1, 'desc']],
            "responsive": true
          }
        )*/
        if (data?.fb?.page_views == null) {
          //hideEls("FB")
          $("#page_views").text("-")
          $("#follows").text("-")
          $("#page_imps").text("-")
          $("#reacciones").text("-")
        } else {
          $("#page_views").text(returnNumber(data.fb.page_views))
          $("#follows").text(returnNumber(data.fb.follows))
          $("#page_imps").text(returnNumber(data.fb.page_imps))
          $("#reacciones").text(returnNumber(data.fb.total_reactions))

        }

        if (data?.fbads?.page_views == null) {
          //hideEls("FB")
          $("#page_viewsfba").text("-")
          $("#ctrfba").text("-")
          $("#page_impsfba").text("-")
          $("#engaged").text("-")
        } else {
          $("#page_viewsfba").text(returnNumber(data.fbads.page_views))
          $("#ctrfba").text(parseFloat((data.fbads.clics / data.fbads.page_imps) * 100).toFixed(2) + "%")
          $("#page_impsfba").text(returnNumber(data.fbads.page_imps_paid))
          $("#engaged").text(returnNumber(data.fbads.engaged_users))

        }
        let secs = parseInt(data?.ga?.avg_time + "")
        let formatted = moment.utc(secs * 1000).format('HH:mm:ss');
        console.log(data, source)
        if (!data?.ga?.sesiones) {
          //hideEls("GA")
          $("#usuarios").text("-")
          $("#sesiones").text("-")
          $("#page_views2").text("-")
          $("#avg_time").text("-")

          $("#usuariosPLS").text("-")
          $("#sesionesPLS").text("-")
          $("#page_viewsPLS").text("-")
          $("#avg_timePLS").text("-")

          $("#usuariosSRC").text("-")
          $("#sesionesSRC").text("-")
          $("#page_viewsSRC").text("-")
          $("#avg_timeSRC").text("-")
        } else {
          $("#usuarios").text(returnNumber(data.ga.usuarios))
          $("#sesiones").text(returnNumber(data.ga.sesiones))
          $("#page_views2").text(returnNumber(data.ga.pageviews))
          $("#avg_time").text(formatted)

          $("#usuariosPLS").text(returnNumber(data.ga.usuarios))
          $("#sesionesPLS").text(returnNumber(data.ga.sesiones))
          $("#page_viewsPLS").text(returnNumber(data.ga.pageviews))
          $("#avg_timePLS").text(formatted)

          $("#usuariosSRC").text(returnNumber(data.ga.usuarios))
          $("#sesionesSRC").text(returnNumber(data.ga.sesiones))
          $("#page_viewsSRC").text(returnNumber(data.ga.pageviews))
          $("#avg_timeSRC").text(formatted)
        }

        if (!data?.gads?.impressions) {
          //hideEls("ADS")
          $("#impresionesADS").text("-")
          $("#clicsADS").text("-")
          $("#llamadasADS").text("-")
          $("#ctrADS").text("-")
        } else {
          $("#impresionesADS").text(returnNumber(data.gads.impressions))
          $("#clicsADS").text(returnNumber(data.gads.clics))
          $("#llamadasADS").text(returnNumber(data.gads.llamadas))
          $("#ctrADS").text(parseFloat(data.gads.ctr || 0).toFixed(2) + "%")
        }

        if (!data?.search) {
          //hideEls("ADS")
          $("#clicsSRC").text("-")
          $("#impresionesSRC").text("-")
          $("#ctrSRC").text("-")
          $("#positionSRC").text("-")
        } else {
          $("#clicsSRC").text(returnNumber(data.search.clicks))
          $("#impresionesSRC").text(returnNumber(data.search.impresiones))
          $("#positionSRC").text(parseFloat(data.search.position || 0).toFixed(2))
          $("#ctrSRC").text(parseFloat(data.search.ctr || 0).toFixed(2) + "%")
        }

        if (!data?.seccion?.sesiones) {
          //hideEls("SEC")
          $("#usuariosSEC").text("-")
          $("#sesionesSEC").text("-")
          $("#page_viewsSEC").text("-")
          $("#avg_timeSEC").text("-")
        } else {
          secs = parseInt(data.seccion.avg_time + "")
          formatted = moment.utc(secs * 1000).format('HH:mm:ss');

          $("#usuariosSEC").text(returnNumber(data.seccion.usuarios))
          $("#sesionesSEC").text(returnNumber(data.seccion.sesiones))
          $("#page_viewsSEC").text(returnNumber(data.seccion.pageviews))
          $("#avg_timeSEC").text(formatted)
        }
        if (detectMob()) {
          document.getElementById("histories-div").classList.remove("d-none")
        }

        data.canales = data.canales.map(v => {
          return { ...v, eventCategory: canalesMap[v.eventCategory] }
        })

        if (data?.gads?.llamadas) {
          data.canales = [...data.canales, {
            sesiones: data.gads.llamadas,
            eventCategory: "llamada"
          }]
        }

        let totales = canales.map(v => {
          let obj = { canal: v, total: 0 }
          obj.total = data?.canales?.filter(l => l.eventCategory == v).reduce((n, { sesiones }) => n + sesiones, 0)
          return { ...obj }
        })

        data.canal_totales = totales
        initStories(data?.canal_totales)

        const canal_ids = ["llamadas", "correo", "whatsapp", "formularios"]
        canal_ids.map((v, i) => {
          $("#" + v).text(returnNumber(totales[i].total))
          $("#" + v + "-slide").text(returnNumber(totales[i].total))
          $("#" + v + "-story").text(returnNumber(totales[i].total))
        })


        const translate = new Map(iso.all().map(v => {
          return [v.alpha2.toLowerCase(), getCountry(v.alpha2)]
        }));

        setLocalizedCountryDictionary(translate)

        const mapObj = mapGraficosData[source]

        setDataSesiones({
          labels: data[mapObj.key].meses.map(v => v.monthParsed).filter(v => v != undefined).reverse(),
          datasets: [{
            label: mapObj.data[0].label,
            type: "bar",
            borderColor: ["#306687", "#24aae4"],
            backgroundColor: ["#306687", "#24aae4"],
            data: [...data[mapObj.key].meses.map(v => v[mapObj.data[0].data])].filter(v => v > 0).reverse(),
            fill: false
          }]
        })

        setDataSesiones1({
          labels: data[mapObj.key].meses.map(v => v.monthParsed).filter(v => v != undefined),
          datasets: [{
            label: mapObj.data[1].label,
            type: "bar",
            borderColor: ["#306687", "#24aae4"],
            backgroundColor: ["#306687", "#24aae4"],
            data: [...data[mapObj.key].meses.map(v => v[mapObj.data[1].data])].filter(v => v > 0),
            fill: false
          }]
        });

        setDataImpresiones({
          labels: data[mapObj.key].meses.map(v => v.monthParsed).filter(v => v != undefined),
          datasets: [{
            label: mapObj.data[2].label,
            data: [...data[mapObj.key].meses.map(v => v[mapObj.data[2].data])].filter(v => v > 0),
            backgroundColor: ["#306687", "#24aae4"]
          }]
        });

        setCurrentDataDesc({ ...mapObj })

        /*const data1 = data?.ga4_pais?.map(v => {
          return {
            country: iso.all().find(i => i.country.startsWith(v.country)).alpha2.toLowerCase(),
            value: v.sesiones
          }
        })

        const all = [...data1].sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0))

        const states = document.getElementsByClassName("svg-map")[0].getElementsByTagName("path")
        let stateNames = []
        for (var i = 0; i < states.length; i++) {
          const idx = data?.ga4_estado?.findIndex(v => {
            if (states[i].getAttribute("name").normalize("NFD").replace(/[\u0300-\u036f]/g, "") == "Mexico") {
              return v.region == "State of Mexico"
            } else {
              return v.region == states[i].getAttribute("name").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            }
          })
          const obj = idx != -1 ? data?.ga4_estado[idx] : null
          if (obj) {
            states[i].style.fill = "blue"
            states[i].style.fillOpacity = obj.usuarios / data?.ga4_estado[0].usuarios
            states[i].region = traducirEstados(obj.region)
            states[i].value = obj.usuarios
            states[i].label = "usuarios"
            data.ga4_estado[idx].color = "rgba(0, 0, 255, " + obj.usuarios / data?.ga4_estado[0].usuarios + ")"
            stateNames = [...stateNames, obj]
          }
        }
        document.getElementById("first-number1").style.backgroundColor = data?.ga4_estado[0].color
        document.getElementById("second-number1").style.backgroundColor = data?.ga4_estado[1].color
        document.getElementById("third-number1").style.backgroundColor = data?.ga4_estado[2].color
        document.getElementById("fourth-number1").style.backgroundColor = data?.ga4_estado[3].color

        setTopEstados(data?.ga4_estado?.slice(0, 4).map(v => {
          return {
            titulo: traducirEstados(v.region),
            descripcion: <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>
              Sesiones: {returnNumber(v.sesiones)}<br />
              Usuarios: {returnNumber(v.usuarios)}
            </p>
          }
        }))

        setTopPaises(data?.ga4_pais?.slice(0, 4).map(v => {
          return {
            titulo: v.country,
            descripcion: <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>
              Sesiones: {returnNumber(v.sesiones)}<br />
              Usuarios: {returnNumber(v.usuarios)}
            </p>
          }
        }))*/

        //document.getElementById("coa").style.fill = "blue"
        setDateChanged(false)
        setDatos({ ...data })
        //setCountryData(all)

        changeMap(source, data)
      }).catch((e) => {
        document.getElementById("loading").classList.add("d-none")
        console.log(JSON.stringify(e), e)
      })
  };

  const mapGraficosData = {
    "hideGA": {
      key: "ga",
      data: [
        {
          label: "Usuarios", data: "usuarios",
          desc: "Cantidad de personas que han visitado tu sitio."
        },
        {
          label: "Vistas de página", data: "pageviews",
          desc: "Cantidad de veces que las personas han visto tu sitio."
        },
        {
          label: "Sesiones", data: "sesiones",
          desc: "Cantidad de veces que las personas han entrado en tu sitio."
        }
      ]
    },
    "hideADS": {
      key: "gads",
      data: [
        {
          label: "Llamadas", data: "llamadas",
          desc: "Cantidad de llamadas que se han generado gracias a nuestra campaña de Google Ads."
        },
        {
          label: "Clics", data: "clics",
          desc: "Cantidad de clics que las personas han hecho en tu sitio."
        },
        {
          label: "Impresiones", data: "impressions",
          desc: "Cantidad de veces que tu anuncio ha aparecido en la búsqueda de una persona."
        }
      ]
    },
    "hideSEC": {
      key: "seccion",
      data: [
        {
          label: "Usuarios", data: "usuarios",
          desc: "Cantidad de personas que han visitado tu sitio."
        },
        {
          label: "Vistas de página", data: "pageviews",
          desc: "Cantidad de veces que las personas han visto tu sitio."
        },
        {
          label: "Sesiones", data: "sesiones",
          desc: "Cantidad de veces que las personas han entrado en tu sitio."
        }
      ]
    },
    "hideSRC": {
      key: "search",
      data: [
        {
          label: "Clics", data: "clicks",
          desc: "Cantidad de clics que las personas han hecho en tu sitio."
        },
        {
          label: "Impresiones", data: "impresiones",
          desc: "Cantidad de veces que tu anuncio ha aparecido en la búsqueda de una persona."
        },
        {
          label: "Posición", data: "position",
          desc: "La posición, en promedio, en la que ha aparecido tu sitio en las búsquedas relacionadas que hacen tus clientes."
        }
      ]
    },
    "hideFB": {
      key: "fb",
      data: [
        {
          label: "Clics", data: "clics",
          desc: "Cantidad de clics que las personas han hecho en tu página."
        },
        {
          label: "Reacciones", data: "total_reactions",
          desc: "Cantidad de veces que tus clientes han reaccionado a las publicaciones de tu página."
        },
        {
          label: "Vistas de página", data: "page_views",
          desc: "Cantidad de veces que tus clientes han visto tu página."
        }
      ]
    },
    "hideFBA": {
      key: "fbads",
      data: [
        {
          label: "Clics", data: "clics",
          desc: "Cantidad de clics que las personas han hecho en tu página."
        },
        {
          label: "Reacciones", data: "total_reactions",
          desc: "Cantidad de veces que tus clientes han reaccionado a las publicaciones de tu página."
        },
        {
          label: "Vistas de página", data: "page_views",
          desc: "Cantidad de veces que tus clientes han visto tu página."
        }
      ]
    }
  }
  useEffect(() => {
    return
    try {
      if (datos.length != 0) {
        const mapObj = mapGraficosData[currentData]
        console.log(currentData, datos)
        if (!mapObj) return

        setDataSesiones({
          labels: datos[mapObj.key].meses.map(v => v.monthParsed).filter(v => v != undefined).reverse(),
          datasets: [{
            label: mapObj.data[0].label,
            type: "bar",
            borderColor: ["#306687", "#24aae4"],
            backgroundColor: ["#306687", "#24aae4"],
            data: [...datos[mapObj.key].meses.map(v => v[mapObj.data[0].data])].filter(v => v > 0).reverse(),
            fill: false
          }]
        })

        setDataSesiones1({
          labels: datos[mapObj.key].meses.map(v => v.monthParsed).filter(v => v != undefined),
          datasets: [{
            label: mapObj.data[1].label,
            type: "bar",
            borderColor: ["#306687", "#24aae4"],
            backgroundColor: ["#306687", "#24aae4"],
            data: [...datos[mapObj.key].meses.map(v => v[mapObj.data[1].data])].filter(v => v > 0),
            fill: false
          }]
        });

        setDataImpresiones({
          labels: datos[mapObj.key].meses.map(v => v.monthParsed).filter(v => v != undefined),
          datasets: [{
            label: mapObj.data[2].label,
            data: [...datos[mapObj.key].meses.map(v => v[mapObj.data[2].data])].filter(v => v > 0),
            backgroundColor: ["#306687", "#24aae4"]
          }]
        });

        setCurrentDataDesc({ ...mapObj })
      }
    } catch (e) {
      console.log(e)
    }
  }, [currentData, datos])

  const [topEstados, setTopEstados] = useState([])
  const [topPaises, setTopPaises] = useState([])

  const changeMap = (currentData, datos) => {
    const mapFuentes = {
      "hideGA": ["ga4_pais", "ga4_estado"],
      "hideADS": ["gads_pais", "gads_estado"],
      "hideSEC": ["seccion_pais", "seccion_estado"],
      "hideSRC": ["search_paises"],
    }

    const mapKeys = {
      "hideGA": "usuarios",
      "hideSEC": "usuarios",
      "hideADS": "clics",
      "hideSRC": "clics",
    }

    if (!mapFuentes[currentData]) {
      document.getElementById("mapa_pais").style.display = "none"
      document.getElementById("mapa_mundo").style.display = "none"
      return
    }

    if (datos[mapFuentes[currentData][1]]) {
      document.getElementById("mapa_pais").style.display = "flex"
      const estadosData = datos[mapFuentes[currentData][1]]
      const states = document.getElementsByClassName("svg-map")[0].getElementsByTagName("path")
      let stateNames = []

      for (var i = 0; i < states.length; i++) {
        const idx = estadosData.findIndex(v => {
          if (states[i].getAttribute("name").normalize("NFD").replace(/[\u0300-\u036f]/g, "") == "Mexico") {
            return v.region == "State of Mexico"
          } else {
            return v.region == states[i].getAttribute("name").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          }
        })
        const obj = idx != -1 ? estadosData[idx] : null
        if (obj) {
          states[i].style.fill = "blue"
          states[i].style.fillOpacity = obj[mapKeys[currentData]] / estadosData[0][mapKeys[currentData]]
          states[i].region = traducirEstados(obj.region)
          states[i].value = obj[mapKeys[currentData]]
          states[i].label = mapKeys[currentData]
          estadosData[idx].color = "rgba(0, 0, 255, " + obj[mapKeys[currentData]] / estadosData[0][mapKeys[currentData]] + ")"
          stateNames = [...stateNames, obj]
        }
      }

      document.getElementById("first-number1").style.backgroundColor = estadosData[0].color
      document.getElementById("second-number1").style.backgroundColor = estadosData[1].color
      document.getElementById("third-number1").style.backgroundColor = estadosData[2].color
      document.getElementById("fourth-number1").style.backgroundColor = estadosData[3].color

      setTopEstados(estadosData.slice(0, 4).map(v => {
        return {
          titulo: traducirEstados(v.region),
          descripcion: mapKeys[currentData] == "usuarios" ?
            <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>
              Sesiones: {returnNumber(v.sesiones)}<br />
              Usuarios: {returnNumber(v.usuarios)}
            </p> :
            <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>
              Clics: {returnNumber(v.clics)}
            </p>
        }
      }))
    } else {
      document.getElementById("mapa_pais").style.display = "none"
    }



    if (datos[mapFuentes[currentData][0]]) {
      document.getElementById("mapa_mundo").style.display = "flex"
      const paisData = datos[mapFuentes[currentData][0]]

      const data1 = paisData.map(v => {
        return {
          country: iso.all().find(i => i.country.startsWith(v.country)).alpha2.toLowerCase(),
          value: v[mapKeys[currentData]]
        }
      })

      const all = [...data1].sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0))
      setCountryData(all)
      setTopPaises(paisData.slice(0, 4).map(v => {
        return {
          titulo: v.country,
          descripcion: mapKeys[currentData] == "usuarios" ?
            <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>
              Sesiones: {returnNumber(v.sesiones)}<br />
              Usuarios: {returnNumber(v.usuarios)}
            </p> :
            <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>
              Clics: {returnNumber(v.clics)}
            </p>
        }
      }))
    } else {
      document.getElementById("mapa_mundo").style.display = "none"
    }
  }

  const mapStates = {
    "Mexico City": "Ciudad de México",
    "State of Mexico": "Estado de México"
  }

  const traducirEstados = (state) => {
    return mapStates[state] || state
  }

  const [stories, setStories] = useState([])

  const initStories = (arr) => {
    setStories([
      {
        content: (props) =>
        (<div style={{ width: "inherit", "height": "inherit" }}>
          <div style={{ minHeight: "50%", background: 'url("' + llamadas + '")', backgroundSize: "cover" }} >
          </div>
          <div className="row" style={{
            minHeight: "25%",
            background: `rgba(238, 90, 48, 1)`,
            display: "flex"
          }}>
            <div className="col-3 mt-3 p-3">
              <h5>
                <i style={{ width: "65px", marginLeft: "1rem" }} className="llamadas-icon"></i>
              </h5>
            </div>
            <div className="col-9 text-left mt-3">
              <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                En {currentMonth}
              </p>
              <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                Recibiste <span id="llamadas-story">{returnNumber(arr[0].total)}</span> llamadas
              </p>
            </div>
          </div>
        </div>)
      },
      {
        content: (props) =>
        (<div style={{ width: "inherit", "height": "inherit" }}>
          <div style={{ minHeight: "50%", background: 'url("' + correos + '")', backgroundSize: "cover" }} >
          </div>
          <div className="row" style={{
            minHeight: "25%",
            background: `rgba(2, 150, 51, 1)`,
            display: "flex"
          }}>
            <div className="col-3 mt-3 p-3">
              <h5>
                <i style={{ width: "65px", marginLeft: "1rem" }} className="correo-icon"></i>
              </h5>
            </div>
            <div className="col-9 text-left mt-3">
              <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                Además, te llegaron
              </p>
              <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                <span id="correos-story">{returnNumber(arr[1].total)}</span> correos con dudas sobre tu negocio.
              </p>
            </div>
          </div>
        </div>)
      },
      {
        content: (props) =>
        (<div style={{ width: "inherit", "height": "inherit" }}>
          <div style={{ minHeight: "50%", background: 'url("' + whatsapp_img + '")', backgroundSize: "cover" }} >
          </div>
          <div className="row" style={{
            minHeight: "25%",
            background: `rgba(6, 133, 178, 1)`,
            display: "flex"
          }}>
            <div className="col-3 mt-3 p-3">
              <h5>
                <i style={{ width: "65px", marginLeft: "1rem" }} className="whatsapp-icon-2"></i>
              </h5>
            </div>
            <div className="col-9 text-left mt-3">
              <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                Tus clientes te enviaron <span id="whatsapp-story">{returnNumber(arr[2].total)}</span>
              </p>
              <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                mensajes de Whatsapp
              </p>
            </div>
          </div>
        </div>)
      },
      {
        content: (props) =>
        (<div style={{ width: "inherit", "height": "inherit" }}>
          <div style={{ minHeight: "50%", background: 'url("' + formas + '")', backgroundSize: "cover" }} >
          </div>
          <div className="row" style={{
            minHeight: "25%",
            background: `rgba(247, 152, 30, 1)`,
            display: "flex"
          }}>
            <div className="col-3 mt-3 p-3">
              <h5>
                <i style={{ width: "65px", marginLeft: "1rem" }} className="forma-icon"></i>
              </h5>
            </div>
            <div className="col-9 text-left mt-3">
              <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                <span id="formularios-story">{returnNumber(arr[3].total)}</span> clientes
              </p>
              <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                te dejaron dus datos para que los contactaras
              </p>
            </div>
          </div>
        </div>)
      },
      {
        content: (props) =>
        (<div style={{ width: "inherit", "height": "inherit" }}>
          <div style={{ minHeight: "50%", background: 'url("' + whatsapp_img + '")', backgroundSize: "cover" }} >
          </div>
          <div className="row" style={{
            minHeight: "25%",
            background: `rgba(6, 133, 178, 1)`,
            display: "flex"
          }}>
            <div className="col-3 mt-3 p-3">
              <h5>
                <i style={{ width: "65px", marginLeft: "1rem" }} className="estadistica-icon"></i>
              </h5>
            </div>
            <div className="col-9 text-left mt-3 pr-3">
              <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                Conoce <span style={{ color: "#ffff00" }}>más</span> sobre cómo
              </p>
              <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                ha <span style={{ color: "#ffff00" }}>crecido tu negocio</span>
                &nbsp;digital en <span style={{ color: "#ffff00" }}>{String(currentMonth).toUpperCase()} 2024</span>
              </p>
            </div>
          </div>
        </div>)
      },
    ])
  }

  let options = {
    aspectRatio: 1,
    legend: { display: false },
    elements: {
      line: {
        tension: 0
      }
    },
    animation: false,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  let options1 = {
    indexAxis: 'y',
    aspectRatio: 1,
    legend: { display: false },
    elements: {
      line: {
        tension: 0
      }
    },
    animation: false,
    scales: {
      yAxes: {
        display: false,

      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  const optionsPolar = {
    aspectRatio: 1,
    animation: false,
    plugins: {
      legend: {
        display: false
      }
    }
  }

  const [dataSesiones, setDataSesiones] = useState({
    labels: ["Ene", "Feb", "Mar", "Abr", "May"],
    datasets: [{
      label: "Usuarios",
      type: "bar",
      borderColor: ["#306687", "#24aae4"],
      backgroundColor: ["#306687", "#24aae4"],
      data: [25, 15, 45, 35, 55],
      fill: false
    },
    {
      label: "Sesiones",
      type: "line",
      borderColor: "#24aae4",
      backgroundColor: "#24aae4",
      data: [30, 20, 50, 40, 60],
    }
    ]
  });

  const [dataSesiones1, setDataSesiones1] = useState({
    labels: ["Ene", "Feb", "Mar", "Abr", "May"],
    datasets: [{
      label: "Usuarios",
      type: "bar",
      borderColor: ["#306687", "#24aae4"],
      backgroundColor: ["#306687", "#24aae4"],
      data: [25, 15, 45, 35, 55],
      fill: false
    }
    ]
  });


  const [dataImpresiones, setDataImpresiones] = useState({
    labels: ["Ene", "Feb", "Mar", "Abr", "May"],
    datasets: [{
      label: 'Impresiones',
      data: [25, 15, 35, 45, 55],
      backgroundColor: ["#306687", "#24aae4"]
    }]
  });

  //const stories = [];


  const [localizedCountryDictionary, setLocalizedCountryDictionary] = useState([])
  const [countryColors, setCountryColors] = useState([])

  function localizeNumber(num, digits) {
    if (typeof num === "undefined") return "";
    return returnNumber(num)
  }

  const getLocalizedText = ({
    countryCode,
    countryValue,
    prefix,
    suffix,
  }) =>
    `${localizedCountryDictionary.get(countryCode.toLocaleLowerCase()) ?? "Unknown"
    }: ${prefix}${localizeNumber(countryValue, 2)}${suffix}`;

  const mapColors = {
    "-1": "lightgrey",
    "0": "#1170AA",
    "1": "#0090CC",
    "2": "#33A7D8",
    "3": "#67BDE2",
  }
  const getStyle = ({ countryValue, countryCode, minValue, maxValue, color }) => {
    const obj = {
      fill: mapColors[String(countryData.findIndex(v => v.country == String(countryCode).toLowerCase()))],
      fillOpacity: 1,
      stroke: "lightgrey",
      strokeWidth: 1,
      strokeOpacity: 1,
      cursor: "pointer",
    }
    return { ...obj }
  };

  const [countryData, setCountryData] = useState([])
  const [currentInfo, setCurrentInfo] = useState(undefined)
  const [tooltipStyle, setTooltipStyle] = useState({
    display: 'none'
  })

  const handleLocationMouseMove = (event) => {
    const tooltipStyle = {
      display: 'block',
      top: event.clientY + 10,
      left: event.clientX - 100
    }
    setTooltipStyle(tooltipStyle);
    setCurrentInfo({
      region: event.target.region,
      sesiones: returnNumber(event.target.value) + " " + event.target.label,
    })
  }
  const handleLocationMouseOut = () => {
    setTooltipStyle({ display: 'none' })
  }
  return (
    <div>
      <div className="loading d-none" id="loading">
        <Spinner animation="border" role="status"></Spinner>
      </div>

      <div className="history text-center d-none" id="histories-div" onClick={() => { $("#histories-div").fadeOut() }}
        style={{ display: "flex", flexDirection: "column" }}>
        <div className="content">
          <div className="mr-3">
            <p className="m-0 p-0" style={{ float: "right", fontSize: "16px" }}>X</p>
          </div>
          {stories.length != 0 &&
            <Stories loop={false} stories={stories} onAllStoriesEnd={() => {
              $("#histories-div").fadeOut()
            }} />
          }
        </div>
      </div>
      <div className="examples__block__map__tooltip bg-primary text-white" style={tooltipStyle}>
        {currentInfo?.region + ": " + currentInfo?.sesiones}
      </div>
      <div className="notOnDownload" style={{ maxHeight: "250px", }}>
        <Carousel swipeable={true} showArrows={true} showIndicators={false} emulateTouch={true}
          infiniteLoop={true} autoPlay={true} showStatus={false} showThumbs={false} className="m-0 p-0" >
          <div id="fondo" style={{ minHeight: "250px" }}>
            <div className="col-12 pt-3 text-white">
              <p className="m-0 p-0" style={{ fontSize: "26px", fontWeight: "bolder" }}>¿Ya sabes cuántos clientes <br></br>
                te contactaron en {String(currentMonth).toUpperCase()} 2024?</p>
              <p className="" style={{ fontSize: "22px" }}>Checa estos números</p>
            </div>
          </div>
          <div className="row" id="fondo3" style={{ minHeight: "250px" }}>
            <div className="col-12 col-md-6 mt-3 mb-3" style={{ borderRight: "2px solid white" }}></div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-3 mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <h5>
                    <i style={{ width: "65px" }} className="llamadas-icon"></i>
                  </h5>
                </div>
                <div className="col-9 text-left mt-3">
                  <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                    En {String(currentMonth)}
                  </p>
                  <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                    Recibiste <span id="llamadas-slide">1250</span> llamadas
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="fondo2" style={{ minHeight: "250px" }}>
            <div className="col-12 col-md-6 mt-3 mb-3" style={{ borderRight: "2px solid white" }}></div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-3 mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <h5>
                    <i style={{ width: "65px" }} className="correo-icon"></i>
                  </h5>
                </div>
                <div className="col-9 text-left mt-3">
                  <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                    Además, te llegaron
                  </p>
                  <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                    <span id="correo-slide">1250</span> correos con dudas sobre tu negocio.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="fondo4" style={{ minHeight: "250px" }}>
            <div className="col-12 col-md-6 mt-3 mb-3" style={{ borderRight: "2px solid white" }}></div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-3 mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <h5>
                    <i style={{ width: "65px" }} className="whatsapp-icon-2"></i>
                  </h5>
                </div>
                <div className="col-9 text-left mt-3">
                  <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                    Tus clientes te enviaron <span id="whatsapp-slide">3500</span>
                  </p>
                  <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                    mensajes de Whatsapp
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="fondo5" style={{ minHeight: "250px" }}>
            <div className="col-12 col-md-6 mt-3 mb-3" style={{ borderRight: "2px solid white" }}></div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-3 mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <h5>
                    <i style={{ width: "65px" }} className="forma-icon"></i>
                  </h5>
                </div>
                <div className="col-9 text-left mt-3">
                  <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                    <span id="formularios-slide">1200</span> clientes
                  </p>
                  <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                    te dejaron dus datos para que los contactaras
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="fondo4" style={{ minHeight: "250px" }}>
            <div className="col-12 col-md-6 mt-3 mb-3" style={{ borderRight: "2px solid white" }}></div>
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-3 mt-3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <h5>
                    <i style={{ width: "65px" }} className="estadistica-icon"></i>
                  </h5>
                </div>
                <div className="col-9 text-left mt-3 pr-3">
                  <p className="m-0 p-0" style={{ fontSize: "20px", fontWeight: "bolder", color: "white" }}>
                    Conoce <span style={{ color: "#ffff00" }}>más</span> sobre cómo
                  </p>
                  <p className="m-0 p-0" style={{ fontSize: "18px", fontWeight: "bold", color: "white" }}>
                    ha <span style={{ color: "#ffff00" }}>crecido tu negocio</span>
                    &nbsp;digital en <span style={{ color: "#ffff00" }}>{String(currentMonth).toUpperCase()} 2024</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div id="firstRow" className="row pr-3 pl-3">
        <div className="col-12 m-0 p-0 mt-1 pt-1 text-center notOnDownload">
          <p>Conoce estrategias para maximizar tu crecimiento
            <button style={{ borderRadius: "5px", marginLeft: "10px", borderColor: "lightblue" }}> Contactar a un especialista</button>
          </p>
        </div>
        <div id="inicio">
          <div id="cuadros">
            <div className="mb-3 col-12 text-center">
              <div className="row">
                <div className="col-12">
                  <div className="row pt-0 pt-md-3 p-3">
                    <div className="col-3 d-none d-md-block"></div>
                    <div className="col-12 col-md-6 input-group" id="dateselectors">
                      <input type="date" onChange={() => {
                        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
                        strDate.setAttribute("max", endDate.value)
                        endDate.setAttribute("min", strDate.value)
                        setDateChanged(true)
                      }} id="strDate" className="form-control" />
                      <input type="date" onChange={() => {
                        let strDate = document.getElementById("strDate"), endDate = document.getElementById("endDate")
                        strDate.setAttribute("max", endDate.value)
                        endDate.setAttribute("min", strDate.value)
                        setDateChanged(true)
                      }} id="endDate" className="form-control" />
                      {dateChanged && <button onClick={() => { getDatos(currentData) }} className="btn btn-primary d-md-none d-block">OK</button>}
                    </div>
                    <div className="col-3 d-none d-md-block text-left">
                      {dateChanged && <button onClick={() => { getDatos(currentData) }} className="btn btn-primary">OK</button>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" id="resumen_metricas">
                <div className="col-md-3 col-6" data-tooltip-id="my-tooltip"
                  data-tooltip-html={"Google Ads: " + returnNumber(datos?.gads?.llamadas) + "<br />" +
                    "Sitio ADN: " + returnNumber((datos?.canal_totales ? datos?.canal_totales[0]?.total : 0) - datos?.gads?.llamadas)}>
                  <div className="card text-white bg-test0 mb-3 text-right mx-auto"
                    style={{ maxWidth: "18rem", backgroundColor: "#ee5a30 !important" }}>
                    <div className="card-body row">
                      <div className="col-3 m-0 p-0 text-center" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        <h5 style={{ fontSize: "30px" }}><i className="fa fa-phone"></i></h5>
                      </div>
                      <div className="col-9 m-0 p-0 text-center border-left">
                        <h4 style={{ fontSize: "30px", margin: "0" }} id="llamadas">12,000</h4>
                        <h4 style={{ fontSize: "16px", margin: "0" }}>llamadas</h4>
                      </div>
                    </div>
                  </div>
                  <div style={{ position: "relative", paddingRight: "20px" }}>
                    <p className="text-white" style={{ position: "absolute", bottom: "2px", right: "5px" }}>
                      <i className="fas fa-info"></i>
                    </p>
                  </div>
                </div>
                <Tooltip id="my-tooltip" />
                <div className="col-md-3 col-6"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html={"Sitio ADN: " + returnNumber((datos?.canal_totales ? datos?.canal_totales[1]?.total : 0))}>
                  <div className="card text-white bg-info mb-3 text-center mx-auto"
                    style={{ maxWidth: "18rem", backgroundColor: "#34B7F1 !important" }}>
                    <div className="card-body row">
                      <div className="col-3 m-0 p-0 text-center" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        <h5 style={{ fontSize: "30px" }}><i className="fa fa-envelope"></i></h5>
                      </div>
                      <div className="col-9 m-0 p-0 text-center border-left">
                        <h4 style={{ fontSize: "30px", margin: "0" }} id="correo">7,562</h4>
                        <h4 style={{ fontSize: "16px", margin: "0" }}>correos</h4>
                      </div>
                    </div>
                  </div>
                  <div style={{ position: "relative", paddingRight: "20px" }}>
                    <p className="text-white" style={{ position: "absolute", bottom: "2px", right: "5px" }}>
                      <i className="fas fa-info"></i>
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-6"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html={"Sitio ADN: " + returnNumber((datos?.canal_totales ? datos?.canal_totales[2]?.total : 0))}>
                  <div className="card text-white bg-success mb-3 text-center mx-auto"
                    style={{ maxWidth: "18rem", backgroundColor: "#25D366 !important" }}>
                    <div className="card-body row">
                      <div className="col-3 m-0 p-0 text-center" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        <h5 style={{ fontSize: "30px" }}><i className="fa fa-whatsapp"></i></h5>
                      </div>
                      <div className="col-9 m-0 p-0 text-center border-left">
                        <h4 style={{ fontSize: "30px", margin: "0" }} id="whatsapp">562</h4>
                        <h4 style={{ fontSize: "16px", margin: "0" }}>mensajes</h4>
                      </div>
                    </div>
                  </div>
                  <div style={{ position: "relative", paddingRight: "20px" }}>
                    <p className="text-white" style={{ position: "absolute", bottom: "2px", right: "5px" }}>
                      <i className="fas fa-info"></i>
                    </p>
                  </div>
                </div>

                <div className="col-md-3 col-6"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html={"Sitio ADN: " + returnNumber((datos?.canal_totales ? datos?.canal_totales[3]?.total : 0))}>
                  <div className="card text-white bg-warning mb-3 text-center mx-auto"
                    style={{ maxWidth: "18rem", backgroundColor: "#34A853 !important" }}>
                    <div className="card-body row">
                      <div className="col-3 m-0 p-0 text-center" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        <h5 style={{ fontSize: "30px" }}><i className="fa fa-address-card"></i></h5>
                      </div>
                      <div className="col-9 m-0 p-0 text-center border-left">
                        <h4 style={{ fontSize: "30px", margin: "0" }} id="formularios">654</h4>
                        <h4 style={{ fontSize: "16px", margin: "0" }}>formularios</h4>
                      </div>
                    </div>
                  </div>
                  <div style={{ position: "relative", paddingRight: "20px" }}>
                    <p className="text-white" style={{ position: "absolute", bottom: "2px", right: "5px" }}>
                      <i className="fas fa-info"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-3 d-none d-md-block"></div>
                <div className="col-md-6 col-12">
                  <Select
                    options={fuentes}
                    autoFocus={false}
                    className="pt-3 mb-3"
                    id="hosts"
                    style={{ width: "100%", paddingTop: "1rem!important", maxHeight: "15px" }}
                    isSearchable={true}
                    defaultValue={{
                      value: "hideGA", label:
                        <div className="text-center p-3"><img style={{ width: "40%" }} src={adnlogo}></img></div>
                    }}
                    onChange={async (e) => {
                      await getDatos(e.value)
                      setCurrentData(e.value)
                      $('*[class^="hide"]').addClass("d-none")
                      $("." + e.value).removeClass("d-none")
                      //changeMap(e.value)
                    }}
                  />
                </div>
                <div className="col-3 d-none d-md-block"></div>
              </div>

            </div>
            <div className="hideSEC mb-3 col-12 text-center d-none">
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-user"></i> Usuarios</h5>
                    <p style={{ fontSize: "32px" }} id="usuariosSEC">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-users"></i> Sesiones</h5>
                    <p style={{ fontSize: "32px" }} id="sesionesSEC">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-eye"></i> Vistas de página</h5>
                    <p style={{ fontSize: "32px" }} id="page_viewsSEC">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-clock-o"></i> Tiempo promedio</h5>
                    <p style={{ fontSize: "32px" }} id="avg_timeSEC">-</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="hideGA mb-3 col-12 text-center">
              <div className="row">

                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-user"></i> Usuarios</h5>
                    <p style={{ fontSize: "32px" }} id="usuarios">-</p>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-users"></i> Sesiones</h5>
                    <p style={{ fontSize: "32px" }} id="sesiones">-</p>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-eye"></i> Vistas de página</h5>
                    <p style={{ fontSize: "32px" }} id="page_views2">-</p>
                  </div>
                </div>

                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-clock-o"></i> Tiempo promedio</h5>
                    <p style={{ fontSize: "32px" }} id="avg_time">-</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidePLS mb-3 col-12 text-center d-none">
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-user"></i> Usuarios</h5>
                    <p style={{ fontSize: "32px" }} id="usuariosPLS">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-users"></i> Sesiones</h5>
                    <p style={{ fontSize: "32px" }} id="sesionesPLS">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-eye"></i> Vistas de página</h5>
                    <p style={{ fontSize: "32px" }} id="page_viewsPLS">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-clock-o"></i> Tiempo promedio</h5>
                    <p style={{ fontSize: "32px" }} id="avg_timePLS">-</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hideFB mb-3 col-12 text-center d-none">
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-eye"></i> Vistas de página</h5>
                    <p style={{ fontSize: "32px" }} id="page_views">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}>Reacciones <img width="30px"
                      src={divierte} /></h5>
                    <p style={{ fontSize: "32px" }} id="reacciones">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-bullhorn"></i> Impresiones</h5>
                    <p style={{ fontSize: "32px" }} id="page_imps">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-share"></i> Seguidores</h5>
                    <p style={{ fontSize: "32px" }} id="follows">-</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hideFBA mb-3 col-12 text-center d-none">
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-eye"></i> Vistas de página</h5>
                    <p style={{ fontSize: "32px" }} id="page_viewsfba">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-percent"></i> CTR</h5>
                    <p style={{ fontSize: "32px" }} id="ctrfba">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-bullhorn"></i> Impresiones</h5>
                    <p style={{ fontSize: "32px" }} id="page_impsfba">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-user"></i> Engaged users</h5>
                    <p style={{ fontSize: "32px" }} id="engaged">-</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hideADS mb-3 col-12 text-center d-none">
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-user"></i> Impresiones</h5>
                    <p style={{ fontSize: "32px" }} id="impresionesADS">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-mouse-pointer"></i> Clics</h5>
                    <p style={{ fontSize: "32px" }} id="clicsADS">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-phone"></i> Llamadas</h5>
                    <p style={{ fontSize: "32px" }} id="llamadasADS">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-percent"></i> CTR</h5>
                    <p style={{ fontSize: "32px" }} id="ctrADS">-</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hideSRC mb-3 col-12 text-center d-none">
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-mouse-pointer"></i> Clics</h5>
                    <p style={{ fontSize: "32px" }} id="clicsSRC">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-users"></i> Impresiones</h5>
                    <p style={{ fontSize: "32px" }} id="impresionesSRC">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-percent"></i> CTR</h5>
                    <p style={{ fontSize: "32px" }} id="ctrSRC">-</p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="p-2" style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
                    <h5 className="text-left" style={{ color: "#0072bf" }}><i className="fa fa-line-chart"></i> Posición</h5>
                    <p style={{ fontSize: "32px" }} id="positionSRC">-</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* PRUEBA DE DISEÑO */}
          <div className="row mt-3 mr-1 ml-1"
            style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
            <div className="col-12 text-center">
              <h4>Métricas relevantes</h4>
            </div>
            <div className="col-12 pl-3 pr-3">
              <div style={{ borderBottom: "2px solid lightblue" }}></div>
            </div>
            <div id="metricas1" className="col-12 col-md-4 text-left pt-2">
              <div style={{ borderBottom: "2px solid lightblue" }}>
                <p id="title-1" className="m-0 text-primary" style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {currentDataDesc?.data ? currentDataDesc?.data[0]?.label : "Título 00"}
                </p>
              </div>
              <div>
                <p id="desc-1" style={{ fontSize: "15px" }}>
                  {currentDataDesc?.data ? currentDataDesc?.data[0]?.desc : "-"}
                </p>
              </div>
              {/* <Bar options={options} data={dataSesiones} className="hideOnDownload"/> */}
              <Bar id="barra1" options={options} data={dataSesiones} />
            </div>
            <div id="metricas2" className="col-12 col-md-4 text-left pt-2">
              <div style={{ borderBottom: "2px solid lightblue" }}>
                <p id="title-2" className="m-0 text-primary" style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {currentDataDesc?.data ? currentDataDesc?.data[1]?.label : "Título 00"}
                </p>
              </div>
              <div>
                <p id="desc-2" style={{ fontSize: "15px" }}>
                  {currentDataDesc?.data ? currentDataDesc?.data[1]?.desc : "-"}
                </p>
                {/* <Bar options={options1} data={dataSesiones1} className="hideOnDownload" /> */}
              </div>
              <Bar id="barra2" options={options1} data={dataSesiones1} />
            </div>
            <div id="metricas3" className="col-12 col-md-4 text-left pt-2">
              <div style={{ borderBottom: "2px solid lightblue" }}>
                <p id="title-3" className="m-0 text-primary" style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {currentDataDesc?.data ? currentDataDesc?.data[2]?.label : "Título 00"}
                </p>
              </div>
              <div>
                <p id="desc-3" style={{ fontSize: "15px" }}>
                  {currentDataDesc?.data ? currentDataDesc?.data[2]?.desc : "-"}
                </p>
                {/* <PolarArea width={"100%"} options={optionsPolar} data={dataImpresiones} className="hideOnDownload"/> */}
              </div>
              <PolarArea id="polar1" width={"100%"} options={optionsPolar} data={dataImpresiones} />
            </div>
            {/* <div className="col-12 p-3 mb-2">
              <div style={{ borderBottom: "2px solid lightblue" }}></div>
            </div>
            <div className="col-6 col-md">
              <div className="row pl-3 pb-2">
                <div className="col-3 m-0 p-0">
                  <div id="first-number" style={{
                    width: "100%", aspectRatio: "1 / 1",
                    boxShadow: "3px 3px 3px lightblue", backgroundColor: "#1170AA",
                    display: "table"
                  }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                      <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>01</p>
                    </div>
                  </div>
                </div>
                <div className="col-9 text-left">
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>Texto</p>
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>Lorem Ipsum is sim ply dumm y
                    text of the printing and type
                    setting industry. Lorem Ipsum
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md">
              <div className="row pl-3 pb-2">
                <div className="col-3 m-0 p-0">
                  <div id="second-number" style={{
                    width: "100%", aspectRatio: "1 / 1",
                    boxShadow: "3px 3px 3px lightblue", backgroundColor: "#0090CC",
                    display: "table"
                  }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                      <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>02</p>
                    </div>
                  </div>
                </div>
                <div className="col-9 text-left">
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>Texto</p>
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>Lorem Ipsum is sim ply dumm y
                    text of the printing and type
                    setting industry. Lorem Ipsum
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md">
              <div className="row pl-3 pb-2">
                <div className="col-3 m-0 p-0">
                  <div id="third-number" style={{
                    width: "100%", aspectRatio: "1 / 1",
                    boxShadow: "3px 3px 3px lightblue", backgroundColor: "#33A7D8",
                    display: "table"
                  }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                      <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>03</p>
                    </div>
                  </div>
                </div>
                <div className="col-9 text-left">
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>Texto</p>
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>Lorem Ipsum is sim ply dumm y
                    text of the printing and type
                    setting industry. Lorem Ipsum
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md">
              <div className="row pl-3 pb-2">
                <div className="col-3 m-0 p-0">
                  <div id="fourth-number" style={{
                    width: "100%", aspectRatio: "1 / 1",
                    boxShadow: "3px 3px 3px lightblue", backgroundColor: "#67BDE2",
                    display: "table"
                  }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                      <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>04</p>
                    </div>
                  </div>
                </div>
                <div className="col-9 text-left">
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>Texto</p>
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "10px" }}>Lorem Ipsum is sim ply dumm y
                    text of the printing and type
                    setting industry. Lorem Ipsum
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div id="mapas">
          <div id="mapa_pais" className="row mt-3 mr-1 ml-1"
            style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
            <div className="col-12 text-center">
              <h4>Mapa de calor de México</h4>
            </div>
            <div className="col-12 pl-3 pr-3">
              <div style={{ borderBottom: "2px solid lightblue" }}></div>
            </div>
            <div id="pais" className="col-12 text-center pt-2">
              <SVGMap
                map={Mexico}
                onLocationMouseMove={handleLocationMouseMove}
                onLocationMouseOut={handleLocationMouseOut}
                onChange={(e) => { console.log(e) }}
                type="link"
              />
            </div>
            <div className="col-6 col-md">
              <div className="row pl-3 pb-2">
                <div className="col-3 m-0 p-0">
                  <div id="first-number1" className="number1"
                    style={{
                      width: "100%", aspectRatio: "1 / 1",
                      display: "table"
                    }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                      <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>01</p>
                    </div>
                  </div>
                </div>
                <div className="col-9 text-left number1-labels">
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>
                    {topEstados.length != 0 ? topEstados[0].titulo : ""}
                  </p>
                  {topEstados.length != 0 ? topEstados[0].descripcion : ""}
                </div>
              </div>
            </div>
            <div className="col-6 col-md">
              <div className="row pl-3 pb-2">
                <div className="col-3 m-0 p-0">
                  <div id="second-number1" className="number1"
                    style={{
                      width: "100%", aspectRatio: "1 / 1",
                      display: "table"
                    }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                      <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>02</p>
                    </div>
                  </div>
                </div>
                <div className="col-9 text-left number1-labels">
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>
                    {topEstados.length != 0 ? topEstados[1].titulo : ""}
                  </p>
                  {topEstados.length != 0 ? topEstados[1].descripcion : ""}
                </div>
              </div>
            </div>
            <div className="col-6 col-md">
              <div className="row pl-3 pb-2">
                <div className="col-3 m-0 p-0">
                  <div id="third-number1" className="number1"
                    style={{
                      width: "100%", aspectRatio: "1 / 1",
                      display: "table"
                    }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                      <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>03</p>
                    </div>
                  </div>
                </div>
                <div className="col-9 text-left number1-labels">
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>
                    {topEstados.length != 0 ? topEstados[2].titulo : ""}
                  </p>
                  {topEstados.length != 0 ? topEstados[2].descripcion : ""}
                </div>
              </div>
            </div>
            <div className="col-6 col-md">
              <div className="row pl-3 pb-2">
                <div className="col-3 m-0 p-0">
                  <div id="fourth-number1" className="number1"
                    style={{
                      width: "100%", aspectRatio: "1 / 1",
                      display: "table"
                    }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                      <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>04</p>
                    </div>
                  </div>
                </div>
                <div className="col-9 text-left number1-labels">
                  <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>
                    {topEstados.length != 0 ? topEstados[3].titulo : ""}
                  </p>
                  {topEstados.length != 0 ? topEstados[3].descripcion : ""}
                </div>
              </div>
            </div>
          </div>

          <div id="mapa_mundo" className="row mt-3 mr-1 ml-1"
            style={{ border: "2px solid lightblue", borderRadius: "3px", backgroundColor: "whitesmoke" }}>
            <div className="col-12 text-center">
              <h4>Mapa de calor mundial</h4>
            </div>
            <div className="col-12 pl-3 pr-3">
              <div style={{ borderBottom: "2px solid lightblue" }}></div>
            </div>
            <div className="col-12 text-center pt-2" id="mundial">
              {countryData.length != 0 &&
                <>
                  <WorldMap
                    color="grey"
                    data={countryData}
                    styleFunction={getStyle}
                    size="responsive"
                    backgroundColor="whitesmoke"
                    strokeOpacity={"1"}
                    valueSuffix=" sesiones"
                    tooltipTextFunction={getLocalizedText}
                  />
                </>
              }
            </div>
            {topPaises.length >= 1 &&
              <div className="col-6 col-md">
                <div className="row pl-3 pb-2">
                  <div className="col-3 m-0 p-0">
                    <div id="first-number" className="number"
                      style={{
                        width: "100%", aspectRatio: "1 / 1", backgroundColor: "#1170AA",
                        display: "table", maxWidth: "55px"
                      }}>
                      <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                        <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>01</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-9 text-left number-labels">
                    <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>
                      {(countryData && localizedCountryDictionary instanceof Map) ? localizedCountryDictionary?.get(countryData[0]?.country) : "-"}
                    </p>
                    {topPaises.length != 0 ? topPaises[0]?.descripcion : ""}
                  </div>
                </div>
              </div>
            }
            {topPaises.length >= 2 &&
              <div className="col-6 col-md">
                <div className="row pl-3 pb-2">
                  <div className="col-3 m-0 p-0">
                    <div id="second-number" className="number"
                      style={{
                        width: "100%", aspectRatio: "1 / 1", backgroundColor: "#0090CC",
                        display: "table", maxWidth: "55px"
                      }}>
                      <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                        <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>02</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-9 text-left number-labels">
                    <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>
                      {(countryData && localizedCountryDictionary instanceof Map) ? localizedCountryDictionary?.get(countryData[1]?.country) : "-"}
                    </p>
                    {topPaises.length != 0 ? topPaises[1]?.descripcion : ""}
                  </div>
                </div>
              </div>}
            {topPaises.length >= 3 &&
              <div className="col-6 col-md">
                <div className="row pl-3 pb-2">
                  <div className="col-3 m-0 p-0 text-right">
                    <div id="third-number" className="number"
                      style={{
                        width: "100%", aspectRatio: "1 / 1", backgroundColor: "#33A7D8",
                        display: "table", maxWidth: "55px"
                      }}>
                      <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                        <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>03</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-9 text-left number-labels">
                    <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>
                      {(countryData && localizedCountryDictionary instanceof Map) ? localizedCountryDictionary?.get(countryData[2]?.country) : "-"}
                    </p>
                    {topPaises.length != 0 ? topPaises[2]?.descripcion : ""}
                  </div>
                </div>
              </div>}
            {topPaises.length >= 4 &&
              <div className="col-6 col-md">
                <div className="row pl-3 pb-2">
                  <div className="col-3 m-0 p-0">
                    <div id="fourth-number" className="number"
                      style={{
                        width: "100%", aspectRatio: "1 / 1", backgroundColor: "#67BDE2",
                        display: "table", maxWidth: "55px"
                      }}>
                      <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                        <p className="text-white" style={{ fontSize: "22px", fontWeight: "bolder" }}>04</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-9 text-left number-labels">
                    <p className="text-secondary m-0 p-0" style={{ fontSize: "18px" }}>
                      {(countryData && localizedCountryDictionary instanceof Map) ? localizedCountryDictionary?.get(countryData[3]?.country) : "-"}
                    </p>
                    {topPaises.length != 0 ? topPaises[3]?.descripcion : ""}
                  </div>
                </div>
              </div>}
          </div>
        </div>
        {/*FIN PRUEBA*/}
      </div>
      <div id="compartir"></div>
      <iframe name="print_frame" width="0" height="0" frameBorder="0" src="about:blank"
        className='printme' style={{ display: 'none' }}
        onLoadedData={(e) => { console.log(window) }}></iframe>
    </div>
  );
};
