import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { types } from '../types/types';
import { startLoading, finishLoading } from './ui';
import { makePOST, makeLogin } from '../helpers/makeRequest';
export const tokenSubject = new BehaviorSubject((localStorage.getItem('email')));

export const token = tokenSubject.asObservable()

export const startLoginEmailPassword = (username, password) => {
    return (dispatch) => {

        dispatch(startLoading());
        /*const p1 = new Promise((res) => setTimeout(() => res("p1"), 1000));

        p1.then((response) => {
            //const data = response.data
            dispatch(login(data.access_token, data.name, username));
            localStorage.setItem('email', username)

            localStorage.setItem('token', "Bearer " + data.access_token)
            localStorage.setItem('idRol', data.idRol)
            localStorage.setItem('idUser', "data.idUser")
            localStorage.setItem('userProps', "{}")
            if (!localStorage.getItem('selectedClient')) {
                localStorage.setItem('selectedClient', '')
            }
            tokenSubject.next("Bearer " + data.access_token)
            dispatch(finishLoading());
        })
            .catch(e => {
                dispatch(finishLoading());
                Swal.fire('Error', 'Por favor ingrese una contraseña o usuario válidos', 'error');
            })*/
        return makePOST("auth/login", { username, password })
            .then((response) => {
                const data = response.data
                dispatch(login(data.token, username));
                localStorage.setItem('email', username)

                localStorage.setItem('token', data.token)
                tokenSubject.next(data.token)
                dispatch(finishLoading());
            })
            .catch(e => {
                dispatch(finishLoading());
                Swal.fire('Error', 'Por favor ingrese una contraseña o usuario válidos', 'error');
            })

    }
}

export const login = (uid, displayName, token) => ({
    type: types.login,
    payload: {
        uid,
        displayName,
        token
    }
});

export const startLogout = () => {
    return async (dispatch) => {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        //localStorage.removeItem('idRol')
        //localStorage.removeItem('idUser')
        //localStorage.removeItem('userProps')
        //localStorage.removeItem('selectedClient')
        tokenSubject.next(null)
        dispatch(logout());
    }
}


export const logout = () => ({
    type: types.logout
})


